import {
  fetchRecord,
  fetchInsoles,
  fetchRSSI,
  fetchConnectionTimestamp,
  fetchEvents,
} from '../firebase/store';
import { streamStrides } from '../firebase/utils';
import {
  fetchIMUUrl,
  fetchCapaUrl,
  fetchRSSIUrl,
  fetchCapaMultipleSensorsUrl,
  fetchRawFrequenciesUrl,
  fetchBatteryCSV,
  fetchBQCSV,
  fetchCapa16CSV,
} from '../firebase/storage';

const VERSION_CSV = 6;
const NB_CAPA = 19;
const NB_COP = 16;

const DIOS_HEADER = [
  'clientTimestamp (ms)', 'timestamp (ms)', 'side',
  'strideLength (m)', 'widthMotion (m)',
  'timeHeelStrike1 (ms)', 'timeHeelStrike2 (ms)', 'timeToeOff (ms)',
  'strideDuration (ms)', 'velocity (m/s)', 'cadence (stride/min)',
  'swingDuration (ms)', 'stanceDuration (ms)', 'swingPercentage (%)', 'stancePercentage (%)',
  'stepTime (ms)', 'stepLength (m)', 'asymmetryParamStepTime (ms)', 'asymmetryParamStepLength (m)',
  'singleSupportDuration (ms)', 'doubleSupportDuration (ms)', 'singleSupportPercentage (%)', 'doubleSupportPercentage (%)',
  'asymmetryParamStrideDuration (ms)', 'asymmetryParamStrideLength (m)', 'asymmetryParamSingleSupportPercentage (%)',
  'strideElevation (cm)', 'flag', 'overflow',
];

const DIOS_METRICS_KEYS = DIOS_HEADER.map(i => i.split(' ')[0]);

const CONNECTION_TIMESTAMP_HEADER = [
  'startConnectionTimestamp',
  'connectedTimestamp',
  'disconnectedTimestamp',
  'side',
];

const RSSI_HEADER = [
  'clientTimestamp',
  'rssi',
  'side',
];

const EVENTS_TRACE_HEADER = [
  'clientTimestamp',
  'timestamp',
  'side',
  'connectionType',
  'powerLevel',
  'voltage',
  'isCharging',
  'systemEvent',
  'stepCounter',
];

const EVENTS_INTERCOM_HEADER = [
  'clientTimestamp',
  'timestamp',
  'side',
  'dtInsole',
  'timestampPairedInsole',
  'dtPairedInsole',
  'macPairedInsole',
];

const EVENTS_GRAVITY_VECTOR_HEADER = [
  'clientTimestamp',
  'timestamp',
  'side',
  'x',
  'y',
  'z',
];

function parseStrideFlag(flag) {
  switch (flag) {
    case 0x00:
      return '';
    case 0x01:
      return 'Micro step';
    case 0x02:
      return 'Weight transfer';
    case 0x03:
      return 'Inactivity';
    case 0x04:
      return 'Uncertainty';
    default:
      return 'Unknown';
  }
}

function parseStrideOverflow(overflow) {
  return (overflow ? '1' : '');
}

function addStrideCSVHeader(recordID) {
  let str = `#versionCSV=${VERSION_CSV},documentId=${recordID}`;
  return fetchRecord(recordID)
    .then((record) => {
      if (record.device) {
        str += `,device_product=${record.device.product},device_versionSDK=${record.device.versionSDK},device_model=${record.device.model},device_device=${record.device.device}`;
      }
      if (record.application) {
        str += `,application_name=${record.application.name},application_version=${record.application.version}`;
      }
      str += `,authorId=${record.authorId},recordType=${record.recordType},startTime=${record.startTime}`;
      return fetchInsoles(recordID);
    }).then((insoles) => {
      insoles.forEach((insole) => {
        const strInsole = `insole_${insole.side}_`;
        if (insole.versions !== undefined) {
          str += `,${strInsole}versions_firmware=${insole.versions.firmware}`;
          str += `,${strInsole}versions_atmel=${insole.versions.atmel}`;
          str += `,${strInsole}versions_hardware=${insole.versions.hardware}`;
          str += `,${strInsole}versions_algoHash=${insole.versions.algoHash}`;
          str += `,${strInsole}versions_firmwareMode=${insole.versions.firmwareMode}`;
          str += `,${strInsole}versions_productID=${insole.versions.productID}`;
          str += `,${strInsole}versions_protocolVersion=${insole.versions.protocolVersion}`;
          str += `,${strInsole}versions_testNumber=${insole.versions.testNumber}`;
        }

        str += `,${strInsole}name=${insole.name}`;
        str += `,${strInsole}macAddress=${insole.macAddress}`;
        str += `,${strInsole}protocolVersion=${insole.protocolVersion}`;
        str += `,${strInsole}number=${insole.number}`;
        str += `,${strInsole}size=${insole.size}`;
      });
      str += '\n';
      return str;
    });
}

function getDiosCSVHeader() {
  let str = '';
  // metrics
  str += DIOS_HEADER.join(',');
  str += ',';
  // capa
  str += [...Array(NB_CAPA).keys()].map(index => `capa${index}`).join(',');
  str += ',';
  // cop
  str += [...Array(NB_COP).keys()].map(index => `cop${index}`).join(',');
  str += '\n';
  return str;
}

function getDiosCSVStride(stride) {
  let str = '';
  str += DIOS_METRICS_KEYS.map((v) => {
    if (v === 'flag') {
      return parseStrideFlag(stride[v]);
    }
    if (v === 'overflow') {
      return parseStrideOverflow(stride[v]);
    }
    return stride[v];
  }).join(',');
  str += ',';

  // capa
  if (stride.capa) {
    str += stride.capa.join(',');
  } else {
    str += [...Array(NB_CAPA).keys()].map(() => '').join(',');
  }
  str += ',';

  // cop
  if (stride.cop) {
    str += stride.cop.join(',');
  } else {
    str += [...Array(NB_COP).keys()].map(() => '').join(',');
  }

  str += '\n';
  return str;
}

function arrayToCSV(header, array) {
  let str = `${header.join(',')}\n`;

  array.forEach((elt) => {
    str += `${header.map(v => elt[v]).join(',')}\n`;
    return undefined;
  });

  return str;
}

function loadUrlXHR(url) {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.requestType = 'text';
    xhr.onload = () => resolve(xhr.response);
    xhr.open('GET', url);
    xhr.send();
  });
}

async function diosCSV(recordID, writer) {
  const encode = TextEncoder.prototype.encode.bind(new TextEncoder());
  writer.write(encode(await addStrideCSVHeader(recordID)));
  writer.write(encode(getDiosCSVHeader()));

  return streamStrides(recordID, 1000, stride => writer.write(encode(getDiosCSVStride(stride))));
}

function connectionTimestampCSV(recordID) {
  return fetchConnectionTimestamp(recordID)
    .then(connections => arrayToCSV(CONNECTION_TIMESTAMP_HEADER, connections));
}

function imuCSV(recordID) {
  return fetchIMUUrl(recordID)
    .then(url => loadUrlXHR(url));
}

function capaCSV(recordID) {
  return fetchCapaUrl(recordID)
    .then(url => loadUrlXHR(url));
}

function rssiStorageCSV(recordID) {
  return fetchRSSIUrl(recordID)
    .then(url => loadUrlXHR(url));
}

function rssiFirestoreCSV(recordID) {
  return fetchRSSI(recordID)
    .then(rssis => arrayToCSV(RSSI_HEADER, rssis));
}

function capaMultipleSensorsTestCSV(recordID) {
  return fetchCapaMultipleSensorsUrl(recordID)
    .then(url => loadUrlXHR(url));
}

function rawFrequenciesCSV(recordID) {
  return fetchRawFrequenciesUrl(recordID)
    .then(url => loadUrlXHR(url));
}

function batteryCSV(recordID) {
  return fetchBatteryCSV(recordID)
    .then(url => loadUrlXHR(url));
}

function bqCSV(recordID) {
  return fetchBQCSV(recordID)
    .then(url => loadUrlXHR(url));
}

function capa16CSV(recordID) {
  return fetchCapa16CSV(recordID)
    .then(url => loadUrlXHR(url));
}

function eventsTraceCSV(recordID) {
  function customArrayToCSV(header, array) {
    let str = `${header.join(',')}\n`;

    array.forEach((elt) => {
      str += `${header.map((v) => {
        if (v === 'stepCounter' && elt[v] === undefined) {
          return elt.strideCounter;
        }
        return elt[v];
      }).join(',')}\n`;
      return undefined;
    });

    return str;
  }

  return fetchEvents(recordID)
    .then(events => events.filter(i => i.event === 'trace'))
    .then(events => customArrayToCSV(EVENTS_TRACE_HEADER, events));
}

function eventsIntercomCSV(recordID) {
  return fetchEvents(recordID)
    .then(events => events.filter(i => i.event === 'intercom'))
    .then(events => arrayToCSV(EVENTS_INTERCOM_HEADER, events));
}

function eventsGravityVectorCSV(recordID) {
  return fetchEvents(recordID)
    .then(events => events.filter(i => i.event === 'gravityVector'))
    .then(events => arrayToCSV(EVENTS_GRAVITY_VECTOR_HEADER, events));
}

export {
  diosCSV,
  connectionTimestampCSV,
  imuCSV,
  capaCSV,
  rssiStorageCSV,
  rssiFirestoreCSV,
  capaMultipleSensorsTestCSV,
  rawFrequenciesCSV,
  batteryCSV,
  bqCSV,
  capa16CSV,
  eventsTraceCSV,
  eventsIntercomCSV,
  eventsGravityVectorCSV,
};
