import React from 'react';
import PropTypes from 'prop-types';

import { Rects, useGraphDimensionsContext } from '@feetme/d3act';

import { getColorLeftSide, getColorRightSide } from '../../utils/colors';

function RectDisconnections({ data, xScale }) {
  const dimensions = useGraphDimensionsContext();

  const xAccessor = d => xScale(d.x1);
  const widthAccessor = d => xScale(d.x2) - xScale(d.x1);
  const keyAccessor = d => `${d.x1}${d.side}`;

  return (
    <g>
      <Rects
        data={data.filter(i => i.side === 'left')}
        xAccessor={xAccessor}
        yAccessor={() => 0}
        widthAccessor={widthAccessor}
        heightAccessor={() => dimensions.boundedHeight}
        keyAccessor={keyAccessor}
        fillAccessor={getColorLeftSide()}
        opacity={0.2}
      />
      <Rects
        data={data.filter(i => i.side === 'right')}
        xAccessor={xAccessor}
        yAccessor={() => 0}
        widthAccessor={widthAccessor}
        heightAccessor={() => dimensions.boundedHeight}
        keyAccessor={keyAccessor}
        fillAccessor={getColorRightSide()}
        opacity={0.2}
      />
    </g>
  );
}

RectDisconnections.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    x1: PropTypes.number,
    x2: PropTypes.number,
    side: PropTypes.string,
  })).isRequired,
  xScale: PropTypes.func.isRequired,
};

export default RectDisconnections;
