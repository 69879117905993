import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';
import InsoleSearch from './components/insole-search';
import RecordList from '../records/components/record-list';

import { fetchInsolesGroup, fetchRecord } from '../firebase/store';
import { initRecords } from '../actions/index';

function mapStateToProps(state) {
  return ({
    limitFetch: state.query.limitFetch,
    macAddress: state.query.macAddress,
    number: state.query.number,
    side: state.query.side,
    size: state.query.size,
    versionNordic: state.query.versionNordic,
    versionAtmel: state.query.versionAtmel,
  });
}

function Insoles(props) {
  const {
    dispatch,
    limitFetch,
    macAddress,
    number,
    side,
    size,
    versionNordic,
    versionAtmel,
  } = props;

  const [inProgress, setInprogress] = useState(false);

  function fetchInsoles() {
    setInprogress(true);
    fetchInsolesGroup(limitFetch, macAddress, number, side, size, versionNordic, versionAtmel)
      .then((insoles) => {
        // retrieve the recordId of each insole queried, and remove duplicate records
        // eslint-disable-next-line dot-notation
        const recordsId = [...new Set(insoles.map(insole => insole['__parent']))];
        return Promise.all(recordsId.map(async recordId => fetchRecord(recordId)));
      })
      .then((records) => {
        dispatch(initRecords(records));
        setInprogress(false);
      });
  }

  useEffect(() => fetchInsoles(), []);

  return (
    <Fragment>
      <PageHeader>
        <PageTitle>Insoles</PageTitle>
      </PageHeader>
      <Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
        <Grid item>
          <InsoleSearch inProgress={inProgress} onRefresh={fetchInsoles} />
        </Grid>
        <Grid item>
          <RecordList />
        </Grid>
      </Grid>
    </Fragment>
  );
}

Insoles.propTypes = {
  dispatch: PropTypes.func.isRequired,
  limitFetch: PropTypes.string.isRequired,
  macAddress: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  side: PropTypes.oneOf(['left', 'right', 'all']).isRequired,
  size: PropTypes.string.isRequired,
  versionNordic: PropTypes.string.isRequired,
  versionAtmel: PropTypes.string.isRequired,
};

export default connect(mapStateToProps)(Insoles);
