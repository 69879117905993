import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Typography,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

import ProgressWait from '../../components/display/progress-wait';
import OrganisationAllRecordsGraphWrapper from './all-records-graph-wrapper';
import OrganisationRecordsTrendWrapper from './records-trend-wrapper';

import { fetchRecords } from '../../firebase/store';

function LoadRecordsWrapper({ orgaId }) {
  const [records, setRecords] = useState([]);
  const [recordsLoaded, setRecordsLoaded] = useState(false);
  const [recordsLoading, setRecordsLoading] = useState(false);

  async function loadRecords() {
    setRecordsLoading(true);
    // no limit in the fetch
    // all authors
    // all application
    // all connection type
    // all record type
    // all records whatever the date
    // retrict to one orga
    setRecords((await fetchRecords('all', 'all', 'all', 'all', 'all', 'all', orgaId))
      .filter(record => record.stopTime !== undefined)
      .map(record => ({
        recordType: record.recordType,
        type: record.type,
        x: record.stopTime,
      }))
      .sort((a, b) => a.x - b.x));

    setRecordsLoaded(true);
    setRecordsLoading(false);
  }

  if (recordsLoading) {
    return (<ProgressWait />);
  }

  if (recordsLoaded === false) {
    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Button variant="contained" color="primary" onClick={loadRecords}>
            Load records
          </Button>
        </Grid>
      </Grid>
    );
  }

  if (records.length === 0) {
    return (
      <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
        <Grid item>
          <InfoIcon />
        </Grid>
        <Grid item>
          <Typography>
            No records for this organisation
          </Typography>
        </Grid>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <OrganisationAllRecordsGraphWrapper records={records} />
      <OrganisationRecordsTrendWrapper records={records} />
    </React.Fragment>
  );
}

LoadRecordsWrapper.propTypes = {
  orgaId: PropTypes.string.isRequired,
};

export default LoadRecordsWrapper;
