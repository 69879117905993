import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import * as Sentry from '@sentry/browser';

import FirebaseUILogin from './components/firebase-ui-login';

import { onAuthStateChanged } from '../firebase/auth';
import { updateQueryAuthorId } from '../actions/index';

const styles = {
  root: {
    marginTop: '40px',
  },
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      logged: undefined,
    };
  }

  componentDidMount() {
    this.unsubscribe = onAuthStateChanged((user) => {
      if (user) {
        Sentry.setUser({ id: user.uid });
        // set current user as default user for query
        this.props.dispatch(updateQueryAuthorId(user.uid));
      }
      this.setState({ logged: !!user });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { logged } = this.state;

    let child;
    switch (logged) {
      case true: {
        const { from } = this.props.location.state || { from: { pathname: '/' } };
        child = (
          <div>
            <CircularProgress />
            <Redirect to={from} />
          </div>
        );
        break;
      }
      case false: {
        child = (
          <FirebaseUILogin />
        );
        break;
      }
      default:
        child = (
          <CircularProgress />
        );
        break;
    }

    return (
      <Grid container direction="row" align="center" justify="center" style={styles.root}>
        <Grid item>
          { child }
        </Grid>
      </Grid>
    );
  }
}

Login.propTypes = {
  location: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    state: PropTypes.object,
  }),
  dispatch: PropTypes.func.isRequired,
};

Login.defaultProps = {
  location: {},
};

export default connect()(Login);
