import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  CircularProgress,
} from '@material-ui/core/';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
    marginLeft: 'auto',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function SearchCard(props) {
  const {
    children,
    inProgress,
    onRefresh,
  } = props;

  const classes = useStyles();

  return (
    <Card>
      <CardHeader title="Search" />
      <CardContent>
        { children }
      </CardContent>
      <CardActions>
        <div className={classes.wrapper}>
          <Button variant="contained" color="primary" disabled={inProgress} onClick={() => onRefresh()}>
            Search
          </Button>
          { inProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
      </CardActions>
    </Card>
  );
}

SearchCard.propTypes = {
  children: PropTypes.element.isRequired,
  inProgress: PropTypes.bool.isRequired,
  onRefresh: PropTypes.func.isRequired,
};

export default SearchCard;
