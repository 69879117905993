import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Radios from '../../components/selection-controls/radios';
import { updateQueryLimitFetch } from '../../actions/index';

const LIMITS = ['10', '20', '100'];

function mapStateToProps(state) {
  return ({
    limitFetch: state.query.limitFetch,
  });
}

class QueryLimitFetch extends React.Component {
  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(ev) {
    this.props.dispatch(updateQueryLimitFetch(ev.target.value));
  }

  render() {
    return (
      <Radios
        title="Limit fetch"
        value={this.props.limitFetch}
        options={LIMITS.map(limit => ({
          key: limit,
          label: limit,
        }))}
        handleChange={this.handleChange}
      />
    );
  }
}

QueryLimitFetch.propTypes = {
  limitFetch: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(QueryLimitFetch);
