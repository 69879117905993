const RECORDS = 'records';
const INSOLES = 'insoles';
const STRIDES = 'dios';
const CONNECTION_TIMESTAMPS = 'connectionTimestamp';
const LINKS = 'links';
const ORGANISATIONS = 'organisations';
const ROLES = 'roles';
const USERS = 'users';
const AUTHORIZATIONS = 'authorizations';
const RSSI = 'rssi';
const EVENTS = 'events';

function checkDoc(ref, id = null) {
  if (id === null) {
    return ref;
  }

  return ref.doc(id);
}

function accessCollection(ref, collection, id) {
  return checkDoc(ref.collection(collection), id);
}

//
// Ref
//
function recordRef(ref, recordId) {
  return accessCollection(ref, RECORDS, recordId);
}

function insoleRef(ref, insoleId) {
  return accessCollection(ref, INSOLES, insoleId);
}

function strideRef(ref, strideId) {
  return accessCollection(ref, STRIDES, strideId);
}

function connectionTimestampRef(ref, connectionTimestampId) {
  return accessCollection(ref, CONNECTION_TIMESTAMPS, connectionTimestampId);
}

function linkRef(ref, linkId) {
  return accessCollection(ref, LINKS, linkId);
}

function orgaRef(ref, orgaId) {
  return accessCollection(ref, ORGANISATIONS, orgaId);
}

function roleRef(ref, userId) {
  return accessCollection(ref, ROLES, userId);
}

function userRef(ref, userId) {
  return accessCollection(ref, USERS, userId);
}

function rssiRef(ref, rssiId) {
  return accessCollection(ref, RSSI, rssiId);
}

function authorizationsRef(ref, orgaId) {
  return accessCollection(ref, AUTHORIZATIONS, orgaId);
}

function eventRef(ref, eventId) {
  return accessCollection(ref, EVENTS, eventId);
}

export {
  recordRef,
  insoleRef,
  strideRef,
  connectionTimestampRef,
  linkRef,
  orgaRef,
  roleRef,
  userRef,
  rssiRef,
  authorizationsRef,
  eventRef,
};
