import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';

import OrganisationItem from '../../components/organisation/index';

import { fetchOrgas } from '../../firebase/store';

class OrganisationList extends React.Component {
  constructor() {
    super();

    this.state = {
      organisations: [],
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    fetchOrgas()
      .then(organisations => this.setState({
        organisations: organisations.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }

          return 0;
        }),
      }));
  }

  render() {
    return (
      <div>
        <IconButton color="secondary" aria-label="refresh" onClick={() => this.fetch()}>
          <RefreshIcon />
        </IconButton>
        { this.state.organisations.map(organisation => (
          <OrganisationItem organisation={organisation} key={organisation.key} />)) }
      </div>
    );
  }
}

export default OrganisationList;
