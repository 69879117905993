function simplifyDisconnection(connectionTimestamp, startTime, stopTime) {
  const res = connectionTimestamp.reduce((acc, i) => {
    if (acc.length === 0) {
      if (i.connected > startTime) {
        acc.push({
          side: i.side,
          start: startTime,
          stop: i.connected,
        });
      }
    } else if (acc[acc.length - 1].stop === 0 && i.connected !== 0) {
      acc[acc.length - 1].stop = i.connected;
    }

    if (i.stop !== 0) {
      acc.push({
        side: i.side,
        start: i.stop,
        stop: 0,
      });
    }

    return acc;
  }, []);

  if (res.length > 0 && res[res.length - 1].stop === 0) {
    res[res.length - 1].stop = stopTime;
  }

  return res;
}

function cleanDisconnections(connectionTimestamp, startTime, stopTime) {
  const connections = connectionTimestamp.filter(i => i.connectedTimestamp > 0)
    .map(i => ({
      start: i.startConnectionTimestamp,
      connected: i.connectedTimestamp,
      stop: i.disconnectedTimestamp,
      side: i.side,
    }));

  return [].concat(
    simplifyDisconnection(connections.filter(i => i.side === 'left'), startTime, stopTime),
    simplifyDisconnection(connections.filter(i => i.side === 'right'), startTime, stopTime),
  );
}

function filterRssi(rssi, rssiSides) {
  return rssi.filter(i => rssiSides.indexOf(i.side) > -1)
    .map(i => ({
      x: i.clientTimestamp,
      y: i.rssi,
      side: i.side,
    }));
}

function filterDisconnections(connectionTimestamp, disconnectionsSides) {
  return connectionTimestamp.filter(i => disconnectionsSides.indexOf(i.side) > -1)
    .map(i => ({
      x1: i.start,
      x2: i.stop,
      side: i.side,
    }));
}

function filterEvents(events, eventTypes) {
  return events.filter(i => eventTypes.indexOf(i.event) > -1);
}

export {
  cleanDisconnections,
  filterRssi,
  filterDisconnections,
  filterEvents,
};
