import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from '@material-ui/core';

import ProgressWait from '../../components/display/progress-wait';
import RecordFlag from './record-flag';

import { streamStrides } from '../../firebase/utils';
import { fetchConnectionTimestamp } from '../../firebase/store';
import { roundPrecision, differencePercentage } from '../../utils/number';
import { distancePerStrides } from '../../utils/metrics';
import { isFlagged } from '../../utils/stride';

function getMaxThresholdDuration(recordType) {
  switch (recordType) {
    case '6min':
      return (60 * 6) + 5;
    case '10meters':
      return 15;
    case '400meters':
      return (60 * 15);
    case '25foot':
      return 15;
    default:
      return undefined;
  }
}

function getMinThresholdDuration(recordType) {
  switch (recordType) {
    case '6min':
      return (60 * 6) - 5;
    case '10meters':
      return 5;
    case '400meters':
      return (60 * 4);
    case '25foot':
      return 5;
    default:
      return undefined;
  }
}

function getMaxThresholdDistance(recordType) {
  switch (recordType) {
    case '10meters':
      return 12;
    case '100meters':
      return 105;
    case '25foot':
      return 9;
    default:
      return undefined;
  }
}

function getMinThresholdDistance(recordType) {
  switch (recordType) {
    case '10meters':
      return 8;
    case '100meters':
      return 95;
    case '25foot':
      return 6;
    default:
      return undefined;
  }
}

const flags = {
  differenceStrides: 0,
  differenceStridesWithoutFlagged: 0,
  numberTimestampsReset: 0,
  stridesFlagged: 0,
  percentageStridesFlagged: 0,
  ratioStridesDuration: 0,
  numberDisconnection: 0,
  walkDuration: 0,
  distance: 0,
  noCapa: 0,
};

// function load the strides in stream and perform health check
function RecordMetricsHealth({ recordId, record }) {
  const [dataLoading, setDataLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  async function loadData() {
    setDataLoading(true);
    // Download connection times
    const connectionTimestamp = await fetchConnectionTimestamp(recordId);

    const numberStrides = { left: 0, right: 0 };
    const numberCleanStrides = { left: 0, right: 0 };
    const timestampReset = {
      left: {
        acc: 0,
        previousTimestamp: 0,
      },
      right: {
        acc: 0,
        previousTimestamp: 0,
      },
    };
    // difference between the first and last stride client timestamp
    let walkdurationMs = 0;
    const distanceManager = distancePerStrides();
    let distance = 0;
    let lastStride;

    // fetch strides in batch
    // on each batch update the flags
    await streamStrides(recordId, 1000, (stride) => {
      if (walkdurationMs === 0) {
        walkdurationMs = stride.clientTimestamp;
      }

      numberStrides[stride.side] += 1;

      if (!isFlagged(stride)) {
        numberCleanStrides[stride.side] += 1;
        distance = distanceManager(stride);
      }

      if (timestampReset[stride.side].previousTimestamp !== 0) {
        if (stride.timestamp <= timestampReset[stride.side].previousTimestamp) {
          timestampReset[stride.side].acc += 1;
        }
      }
      timestampReset[stride.side].previousTimestamp = stride.timestamp;

      if (stride.capa.length === 0) {
        flags.noCapa += 1;
      }

      lastStride = stride;
    });

    walkdurationMs = lastStride.clientTimestamp - walkdurationMs;

    const totalStrides = numberStrides.left + numberStrides.right;
    const totalCleanStrides = numberCleanStrides.left + numberCleanStrides.right;

    flags.differenceStrides = differencePercentage(
      numberStrides.left,
      numberStrides.right,
    );

    flags.differenceStridesWithoutFlagged = differencePercentage(
      numberCleanStrides.left,
      numberCleanStrides.right,
    );
    flags.numberTimestampsReset = timestampReset.left.acc + timestampReset.right.acc;
    flags.stridesFlagged = totalStrides - totalCleanStrides;
    flags.percentageStridesFlagged = roundPrecision((flags.stridesFlagged / totalStrides) * 100);
    flags.ratioStridesDuration = roundPrecision(totalCleanStrides / (walkdurationMs / 1000));
    flags.numberDisconnection = connectionTimestamp
      .filter(i => i.disconnectedTimestamp !== 0).length;
    flags.walkDuration = walkdurationMs / 1000;
    flags.distance = roundPrecision(distance);

    setDataLoaded(true);
    setDataLoading(false);
  }

  if (dataLoading) {
    return (<ProgressWait />);
  }

  if (dataLoaded === false) {
    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Button variant="contained" color="primary" onClick={loadData}>
            Load health check
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card>
      <CardHeader title="Record metrics health" />
      <CardContent>
        <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
          <Grid item>
            <RecordFlag
              name="Difference left/right strides"
              value={flags.differenceStrides}
              unit="%"
              maxThreshold={10}
            />
          </Grid>
          <Grid item>
            <RecordFlag
              name="Difference left/right strides without flagged strides"
              value={flags.differenceStridesWithoutFlagged}
              unit="%"
              maxThreshold={10}
            />
          </Grid>
          <Grid item>
            <RecordFlag
              name="Number of timestamps reset"
              value={flags.numberTimestampsReset}
              maxThreshold={1}
            />
          </Grid>
          <Grid item>
            <RecordFlag
              name="Number strides flagged"
              value={flags.stridesFlagged}
              maxThreshold={1}
            />
          </Grid>
          <Grid item>
            <RecordFlag
              name="Percentage of strides flagged"
              value={flags.percentageStridesFlagged}
              unit="%"
            />
          </Grid>
          <Grid item>
            <RecordFlag
              name="Ratio number of strides / walk duration"
              value={flags.ratioStridesDuration}
              maxThreshold={3}
              minThreshold={2}
            />
          </Grid>
          <Grid item>
            <RecordFlag
              name="Number of disconnection"
              value={flags.numberDisconnection}
              maxThreshold={1}
            />
          </Grid>
          <Grid item>
            <RecordFlag
              name="Walk duration (inactivities not included)"
              unit="s"
              value={flags.walkDuration}
              maxThreshold={getMaxThresholdDuration(record.recordType)}
              minThreshold={getMinThresholdDuration(record.recordType)}
            />
          </Grid>
          <Grid item>
            <RecordFlag
              name="Distance (flagged strides are not included)"
              unit="m"
              value={flags.distance}
              maxThreshold={getMaxThresholdDistance(record.recordType)}
              minThreshold={getMinThresholdDistance(record.recordType)}
            />
          </Grid>
          <Grid item>
            <RecordFlag
              name="Strides with no capa"
              value={flags.noCapa}
              maxThreshold={1}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}

RecordMetricsHealth.propTypes = {
  recordId: PropTypes.string.isRequired,
  record: PropTypes.shape({
    recordType: PropTypes.string,
  }).isRequired,
};

export default RecordMetricsHealth;
