import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { updateQueryOrganisationId } from '../../actions/index';
import { fetchOrgas } from '../../firebase/store';

function mapStateToProps(state) {
  return ({
    organisationId: state.query.organisationId,
  });
}

function CustomPopper(props) {
  return <Popper {...props} style={{ width: 'fit-content' }} placement="bottom-start" />;
}

class QueryOrganisationId extends React.Component {
  constructor() {
    super();

    this.state = {
      organisations: [],
    };
  }

  componentDidMount() {
    fetchOrgas()
      .then(organisations => this.setState({
        organisations: [{ key: 'all', label: 'All' }, ...organisations.map(i => ({ key: i.key, label: i.name }))],
      }));
  }

  render() {
    if (this.state.organisations.length === 0) {
      return null;
    }

    return (
      <Autocomplete
        fullWidth
        style={{ minWidth: 100 }}
        value={this.state.organisations.find(i => i.key === this.props.organisationId) || {}}
        options={this.state.organisations}
        getOptionSelected={(option, value) => option.key === value.key}
        getOptionLabel={option => option.label}
        renderInput={params => <TextField {...params} label="Organisation" />}
        PopperComponent={CustomPopper}
        onChange={(ev, newValue) => {
          if (newValue === null) {
            this.props.dispatch(updateQueryOrganisationId('all'));
          } else {
            this.props.dispatch(updateQueryOrganisationId(newValue.key));
          }
        }}
      />
    );
  }
}

QueryOrganisationId.propTypes = {
  organisationId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(QueryOrganisationId);
