import firebase from './index';

const storage = firebase.storage();

const RECORDS_REF = 'records';
const IMU_REF = 'imu';
const CAPA_REF = 'capa';
const RSSI_REF = 'rssi';
const CAPA_MULTIPLE_SENSORS_TEST_REF = 'capa-multiple-sensors-test';
const RAW_FREQUENCIES_REF = 'raw-frequencies';
const BATTERY_REF = 'battery';
const BQ_REF = 'bq';
const CAPA16_REF = 'capa16';

function fetchIMUUrl(recordID) {
  return storage.ref(RECORDS_REF).child(recordID).child(IMU_REF).getDownloadURL();
}

function fetchCapaUrl(recordID) {
  return storage.ref(RECORDS_REF).child(recordID).child(CAPA_REF).getDownloadURL();
}

function fetchRSSIUrl(recordID) {
  return storage.ref(RECORDS_REF).child(recordID).child(RSSI_REF).getDownloadURL();
}

function fetchCapaMultipleSensorsUrl(recordID) {
  return storage.ref(RECORDS_REF).child(recordID)
    .child(CAPA_MULTIPLE_SENSORS_TEST_REF).getDownloadURL();
}

function fetchRawFrequenciesUrl(recordID) {
  return storage.ref(RECORDS_REF).child(recordID).child(RAW_FREQUENCIES_REF).getDownloadURL();
}

function fetchBatteryCSV(recordID) {
  return storage.ref(RECORDS_REF).child(recordID).child(BATTERY_REF).getDownloadURL();
}

function fetchBQCSV(recordID) {
  return storage.ref(RECORDS_REF).child(recordID).child(BQ_REF).getDownloadURL();
}

function fetchCapa16CSV(recordID) {
  return storage.ref(RECORDS_REF).child(recordID).child(CAPA16_REF).getDownloadURL();
}

export {
  fetchIMUUrl,
  fetchCapaUrl,
  fetchRSSIUrl,
  fetchCapaMultipleSensorsUrl,
  fetchRawFrequenciesUrl,
  fetchBatteryCSV,
  fetchBQCSV,
  fetchCapa16CSV,
};
