import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';

import RecordList from './components/record-list';
import RecordSearch from './components/record-search';
import LoadMoreRecords from './components/load-more-records';

import { fetchRecords } from '../firebase/store';
import { initRecords, updateQuerys } from '../actions/index';
import { parseQueryString, getQueryString } from '../utils/query-string';

function mapStateToProps(state) {
  return ({
    limitFetch: state.query.limitFetch,
    applicationType: state.query.applicationType,
    authorId: state.query.authorId,
    organisationId: state.query.organisationId,
    connectionType: state.query.connectionType,
    recordType: state.query.recordType,
    beforeDate: state.query.beforeDate,
  });
}

class Records extends React.Component {
  constructor() {
    super();

    this.state = {
      inProgress: true,
    };

    this.fetchWrapper = this.fetchWrapper.bind(this);
  }

  componentDidMount() {
    const searchQuery = parseQueryString(this.props.location.search);
    const searchParameters = {
      limitFetch: this.props.limitFetch,
      authorId: this.props.authorId,
      organisationId: this.props.organisationId,
      applicationType: this.props.applicationType,
      connectionType: this.props.connectionType,
      recordType: this.props.recordType,
      beforeDate: this.props.beforeDate,
      ...searchQuery,
    };

    this.props.dispatch(updateQuerys(searchParameters));
    this.fetch(searchParameters);
  }

  fetchWrapper() {
    const {
      limitFetch,
      authorId,
      organisationId,
      applicationType,
      connectionType,
      recordType,
      beforeDate,
    } = this.props;

    this.setState({ inProgress: true });

    this.props.history.push({
      search: getQueryString(
        limitFetch,
        authorId,
        organisationId,
        applicationType,
        connectionType,
        recordType,
        beforeDate,
      ),
    });

    this.fetch(this.props);
  }

  fetch({
    limitFetch,
    authorId,
    organisationId,
    applicationType,
    connectionType,
    recordType,
    beforeDate,
  }) {
    fetchRecords(
      limitFetch,
      authorId,
      applicationType,
      connectionType,
      recordType,
      beforeDate,
      organisationId,
    ).then((records) => {
      this.props.dispatch(initRecords(records));
      this.setState({ inProgress: false });
    });
  }

  render() {
    return (
      <div>
        <PageHeader>
          <PageTitle>Records</PageTitle>
        </PageHeader>
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item>
            <RecordSearch inProgress={this.state.inProgress} onRefresh={this.fetchWrapper} />
          </Grid>
          <Grid item>
            <RecordList />
          </Grid>
          { (!this.state.inProgress) && (
            <Grid item>
              <LoadMoreRecords />
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

Records.propTypes = {
  limitFetch: PropTypes.string.isRequired,
  authorId: PropTypes.string.isRequired,
  organisationId: PropTypes.string.isRequired,
  applicationType: PropTypes.string.isRequired,
  connectionType: PropTypes.string.isRequired,
  recordType: PropTypes.string.isRequired,
  beforeDate: PropTypes.string.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(withRouter(Records));
