import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function RecordWalkingAids(props) {
  return (
    <div>
      <Typography variant="subtitle1">
        Walking Aids
      </Typography>
      <pre>
        <code className="prettyprint">
          { JSON.stringify(props.walkingAids, null, 2) }
        </code>
      </pre>
    </div>
  );
}

RecordWalkingAids.propTypes = {
  walkingAids: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.string,
    left: PropTypes.boolean,
    right: PropTypes.boolean,
    notes: PropTypes.string,
  })).isRequired,
};

export default RecordWalkingAids;
