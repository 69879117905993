import {
  INIT_RECORDS,
  ADD_RECORDS,

  UPDATE_QUERY_APPLICATION_TYPE,
  UPDATE_QUERY_LIMIT_FETCH,
  UPDATE_QUERY_AUTHOR_ID,
  UPDATE_QUERY_ORGANISATION_ID,
  UPDATE_QUERY_CONNECTION_TYPE,
  UPDATE_QUERY_RECORD_TYPE,
  UPDATE_QUERY_BEFORE_DATE,
  UPDATE_QUERY_INSOLE_MAC,
  UPDATE_QUERY_INSOLE_NUMBER,
  UPDATE_QUERY_INSOLE_SIZE,
  UPDATE_QUERY_INSOLE_SIDE,
  UPDATE_QUERY_INSOLE_VERSION_NORDIC,
  UPDATE_QUERY_INSOLE_VERSION_ATMEL,
  UPDATE_QUERYS,

  UPDATE_WS_CONNECTION_STATE,

  FLUSH,
} from './types';

function makeActionCreator(type, ...argNames) {
  return (...args) => {
    const action = { type };
    argNames.forEach((arg, index) => {
      action[argNames[index]] = args[index];
      return undefined;
    });

    return action;
  };
}

export const initRecords = makeActionCreator(INIT_RECORDS, 'records');
export const addRecords = makeActionCreator(ADD_RECORDS, 'records');

export const updateQueryApplicationType = makeActionCreator(UPDATE_QUERY_APPLICATION_TYPE, 'applicationType');
export const updateQueryLimitFetch = makeActionCreator(UPDATE_QUERY_LIMIT_FETCH, 'limitFetch');
export const updateQueryAuthorId = makeActionCreator(UPDATE_QUERY_AUTHOR_ID, 'authorId');
export const updateQueryOrganisationId = makeActionCreator(UPDATE_QUERY_ORGANISATION_ID, 'organisationId');
export const updateQueryConnectionType = makeActionCreator(UPDATE_QUERY_CONNECTION_TYPE, 'connectionType');
export const updateQueryRecordType = makeActionCreator(UPDATE_QUERY_RECORD_TYPE, 'recordType');
export const updateQueryBeforeDate = makeActionCreator(UPDATE_QUERY_BEFORE_DATE, 'beforeDate');
export const updateQueryInsoleMac = makeActionCreator(UPDATE_QUERY_INSOLE_MAC, 'macAddress');
export const updateQueryInsoleNumber = makeActionCreator(UPDATE_QUERY_INSOLE_NUMBER, 'number');
export const updateQueryInsoleSize = makeActionCreator(UPDATE_QUERY_INSOLE_SIZE, 'size');
export const updateQueryInsoleSide = makeActionCreator(UPDATE_QUERY_INSOLE_SIDE, 'side');
export const updateQueryInsoleVersionNordic = makeActionCreator(UPDATE_QUERY_INSOLE_VERSION_NORDIC, 'versionNordic');
export const updateQueryInsoleVersionAtmel = makeActionCreator(UPDATE_QUERY_INSOLE_VERSION_ATMEL, 'versionAtmel');
export const updateQuerys = makeActionCreator(UPDATE_QUERYS, 'query');

export const updateWSConnectionState = makeActionCreator(UPDATE_WS_CONNECTION_STATE, 'state');

export const flush = makeActionCreator(FLUSH);
