import React from 'react';
import PropTypes from 'prop-types';
import { extent } from 'd3-array';
import { curveMonotoneX } from 'd3-shape';
import { scaleLinear } from 'd3-scale';

import {
  Path,
  Axis,
  accessorPropType,
  useGraphDimensionsContext,
} from '@feetme/d3act';

import { SIDES } from '../../utils/sides';
import { getColorXYZ } from '../../utils/colors';

function TimelineRecordImu(props) {
  const {
    gyro,
    accelero,
    xAccessor,
    noAxis,
  } = props;
  const dimensions = useGraphDimensionsContext();

  const xyz = [0, 1, 2];

  const yAccessor = d => d.y;

  const yScaleGyro = scaleLinear()
    .domain(extent(gyro.map(yAccessor).flat()))
    .range([dimensions.boundedHeight, 0]);

  const yScaleAccelero = scaleLinear()
    .domain(extent(accelero.map(yAccessor).flat()))
    .range([dimensions.boundedHeight, 0]);

  const yAccessorGyroScaled = d => yScaleGyro(yAccessor(d));
  const yAccessorAcceleroScaled = d => yScaleAccelero(yAccessor(d));

  return (
    <React.Fragment>
      <g>
        {SIDES.map(i => i.key).map(side => xyz.map(i => (
          <Path
            key={i}
            data={
              gyro
                .filter(frame => frame.side === side)
                .map(frame => ({ x: frame.x, y: frame.y[i] }))
            }
            xAccessor={xAccessor}
            yAccessor={yAccessorGyroScaled}
            interpolation={curveMonotoneX}
            strokeWidth={1}
            stroke={getColorXYZ()[i]}
          />
        )))}
        {SIDES.map(i => i.key).map(side => xyz.map(i => (
          <Path
            key={i}
            data={
              accelero
                .filter(frame => frame.side === side)
                .map(frame => ({ x: frame.x, y: frame.y[i] }))
            }
            xAccessor={xAccessor}
            yAccessor={yAccessorAcceleroScaled}
            interpolation={curveMonotoneX}
            strokeWidth={1}
            stroke={getColorXYZ()[i]}
          />
        )))}
      </g>
      { !noAxis && (
        <React.Fragment>
          <Axis
            dimension="y"
            scale={yScaleGyro}
            keyAccessor={d => d}
            padding={20}
          />
          <Axis
            dimension="y"
            scale={yScaleAccelero}
            keyAccessor={d => d}
            padding={50}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

TimelineRecordImu.propTypes = {
  gyro: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.arrayOf(PropTypes.number),
    side: PropTypes.string,
  })).isRequired,
  accelero: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.arrayOf(PropTypes.number),
    side: PropTypes.string,
  })).isRequired,
  xAccessor: accessorPropType.isRequired,
  noAxis: PropTypes.bool,
};

TimelineRecordImu.defaultProps = {
  noAxis: false,
};

export default TimelineRecordImu;
