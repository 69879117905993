import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import { min, max } from 'd3-array';

import RecordFlag from './record-flag';

function getCapaValues(capa) {
  return capa.map(i => i.slice(2, i.length - 2));
}

function getGyroValues(imu) {
  return [imu.map(i => i[2]), imu.map(i => i[3]), imu.map(i => i[4])];
}

function getAcceleroValues(imu) {
  return [imu.map(i => i[5]), imu.map(i => i[6]), imu.map(i => i[7])];
}

function fragmentMinMax(values, name) {
  return (
    <Fragment>
      <Grid item>
        <RecordFlag
          name={`Min ${name} value`}
          value={min(values)}
        />
      </Grid>
      <Grid item>
        <RecordFlag
          name={`Max ${name} value`}
          value={max(values)}
        />
      </Grid>
    </Fragment>
  );
}

function RecordRawFlags({ capa, imu }) {
  const capaValues = getCapaValues(capa).flat();

  const [gyroX, gyroY, gyroZ] = getGyroValues(imu);
  const [acceleroX, acceleroY, acceleroZ] = getAcceleroValues(imu);

  return (
    <Card>
      <CardHeader title="Raw flags" />
      <CardContent>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={10}>
          { capa.length > 0 && (
            <Grid item>
              <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                { fragmentMinMax(capaValues, 'capa') }
              </Grid>
            </Grid>
          )}
          { imu.length > 0 && (
            <Grid item>
              <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                { fragmentMinMax(gyroX, 'gyro X') }
                { fragmentMinMax(gyroY, 'gyro Y') }
                { fragmentMinMax(gyroZ, 'gyro Z') }
              </Grid>
            </Grid>
          )}
          { imu.length > 0 && (
            <Grid item>
              <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={1}>
                { fragmentMinMax(acceleroX, 'accelero X') }
                { fragmentMinMax(acceleroY, 'accelero Y') }
                { fragmentMinMax(acceleroZ, 'accelero Z') }
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
}

RecordRawFlags.propTypes = {
  capa: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]))),
  imu: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]))),
};

RecordRawFlags.defaultProps = {
  capa: [],
  imu: [],
};

export default RecordRawFlags;
