import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function RecordDevice(props) {
  return (
    <div>
      <Typography variant="subtitle1">
        Device
      </Typography>
      <pre>
        <code className="prettyprint">
          { JSON.stringify(props.device, null, 2) }
        </code>
      </pre>
    </div>
  );
}

RecordDevice.propTypes = {
  device: PropTypes.shape({}).isRequired,
};

export default RecordDevice;
