import React from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';

import {
  Rects,
  useGraphDimensionsContext,
} from '@feetme/d3act';

function TimelineRecordEvents({ data, xScale, updateTooltip }) {
  const dimensions = useGraphDimensionsContext();

  const xAccessor = d => d.clientTimestamp;
  const xAccessorScaled = d => xScale(xAccessor(d));

  const yScale = scaleLinear()
    .domain([0, 1])
    .range([dimensions.boundedHeight, 0]);
  const yAccessorScaled = () => yScale(0.5);

  function handleMouseEnter(d) {
    if (updateTooltip === undefined) {
      return;
    }

    const x = xAccessorScaled(d) + dimensions.marginLeft;
    const y = yAccessorScaled(d) + dimensions.marginTop;

    updateTooltip({
      opacity: 1,
      transform: `translate(calc(-50% + ${x}px), calc(-100% + ${y}px - 15px))`,
      type: `${d.event}-event`,
      data: d,
    });
  }

  function handleMouseLeave() {
    if (updateTooltip === undefined) {
      return;
    }

    updateTooltip({
      opacity: 0,
    });
  }

  return (
    <g>
      <Rects
        data={data}
        keyAccessor={d => d.key}
        xAccessor={xAccessorScaled}
        yAccessor={yAccessorScaled}
        widthAccessor={6}
        heightAccessor={6}
        fillAccessor={(d) => {
          if (d.event === 'trace') {
            return 'yellow';
          }

          if (d.event === 'intercom') {
            return 'green';
          }

          if (d.event === 'gravityVector') {
            return 'purple';
          }

          return 'black';
        }}
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        style={{
          cursor: 'pointer',
        }}
      />
    </g>
  );
}

TimelineRecordEvents.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    clientTimestamp: PropTypes.number,
    key: PropTypes.string,
  })).isRequired,
  xScale: PropTypes.func.isRequired,
  updateTooltip: PropTypes.func,
};

TimelineRecordEvents.defaultProps = {
  updateTooltip: undefined,
};

export default TimelineRecordEvents;
