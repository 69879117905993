import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';

import ToolBarDrawer from './components/toolbar-drawer';

import Records from '../records/index';
import Record from '../record/index';
import Insoles from '../insoles/index';
import User from '../user/index';
import Users from '../users/index';
import Links from '../links/index';
import Organisations from '../organisations/index';
import Organisation from '../organisation/index';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: theme.mixins.toolbar,
  content: {
    // Necessary for resize of graph components
    minWidth: '67%',
    flexGrow: 1,
    padding: theme.spacing(3),
  },
});

function App({ classes }) {
  return (
    <div className={classes.root}>
      <ToolBarDrawer />
      <main className={classes.content}>
        <div style={{ width: '100%' }}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/records" component={Records} />
            <Route exact path="/record/:recordID" component={Record} />
            <Route exact path="/insoles" component={Insoles} />
            <Route exact path="/users" component={Users} />
            <Route exact path="/user" component={User} />
            <Route exact path="/links" component={Links} />
            <Route exact path="/organisations" component={Organisations} />
            <Route exact path="/organisation/:orgaId" component={Organisation} />
            <Redirect to="/records" />
          </Switch>
        </div>
      </main>
    </div>
  );
}

App.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
