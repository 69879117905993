const TYPES = [{
  key: 'dios',
  label: 'Metric',
  source: ['android-dashboard', 'android-evaluation', 'android-rehab'],
}, {
  key: 'capa-imu',
  label: 'Capa & IMU',
  source: ['android-dashboard'],
}, {
  key: 'capa16-imu',
  label: 'Capa16 & IMU',
  source: ['android-dashboard'],
}, {
  key: 'raw-frequencies',
  label: 'Raw frequencies',
  source: ['android-dashboard'],
}, {
  key: 'charge',
  label: 'Charge',
  source: ['android-dashboard'],
}, {
  key: 'hybrid',
  label: 'Hybrid',
  source: ['android-dashboard'],
}, {
  key: 'rehab',
  label: 'Rehab',
  source: ['android-rehab', 'ios-rehab'],
}, {
  key: 'capa-multiple-sensors-test',
  label: 'Capa multiple sensors test',
  source: ['android-dashboard'],
}, {
  key: 'dynamic',
  label: 'Dynamic',
  source: ['android-lokara'],
}, {
  key: 'static',
  label: 'Static',
  source: ['android-lokara'],
}];

function getTypes(application) {
  if (application === 'all') {
    return TYPES;
  }

  return TYPES.filter(type => type.source.indexOf(application) > -1);
}

export {
  TYPES,
  getTypes,
};
