import { useState, useEffect } from 'react';

import { userID } from '../firebase/auth';
import { isGroupFeetMe } from '../firebase/store';

function useIsInFeetMeGroup() {
  const [isInFeetMeGroup, setIsInFeetMeGroup] = useState(false);

  useEffect(() => {
    isGroupFeetMe(userID()).then(res => setIsInFeetMeGroup(res));
  }, []);

  return isInFeetMeGroup;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  useIsInFeetMeGroup,
};
