import React from 'react';
import PropTypes from 'prop-types';

import { useGraphDimensionsContext } from '@feetme/d3act';

function LineTimesRecord({ data, xScale }) {
  const dimensions = useGraphDimensionsContext();

  return data.map(d => (
    <line
      key={d}
      stroke="black"
      x1={xScale(d)}
      x2={xScale(d)}
      y1={0}
      y2={dimensions.boundedHeight}
    />
  ));
}

LineTimesRecord.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  xScale: PropTypes.func.isRequired,
};

export default LineTimesRecord;
