import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import QueryFieldToggle from '../../components/display/query-field-toggle';

import { updateQueryAuthorId } from '../../actions/index';
import { userID } from '../../firebase/auth';

function mapStateToProps(state) {
  return ({
    authorId: state.query.authorId,
  });
}

function QueryAuthorID({ authorId, dispatch }) {
  return (
    <QueryFieldToggle
      value={authorId}
      switchValue="authorId"
      switchLabel="All authors"
      onSwitchChange={ev => dispatch(updateQueryAuthorId(ev.target.checked ? 'all' : userID()))}
      textLabel="Author id"
      onTextChange={ev => dispatch(updateQueryAuthorId(ev.target.value))}
    />
  );
}

QueryAuthorID.propTypes = {
  authorId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(QueryAuthorID);
