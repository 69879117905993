import React from 'react';
import PropTypes from 'prop-types';

function DateDisplay(props) {
  return (
    <span>
      { new Date(props.value).toLocaleString('en-GB') }
    </span>
  );
}

DateDisplay.propTypes = {
  value: PropTypes.number.isRequired,
};

export default DateDisplay;
