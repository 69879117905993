import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

function displayConnectionType(connectionType) {
  switch (connectionType) {
    case 0:
      return 'metrics';
    case 1:
      return 'raw';
    case 2:
      return 'synchro';
    case 3:
      return 'hybrid';
    case 4:
      return 'dynamic';
    case 5:
      return 'capa 16';
    default:
      return `unknown (${connectionType})`;
  }
}

function displayPowerLevel(powerLevel) {
  switch (powerLevel) {
    case 0:
    case 1:
    case 2:
      return `L${powerLevel}`;
    default:
      return `unknown (${powerLevel})`;
  }
}

function displayVoltage(voltage) {
  return Math.round(voltage * 100) / 100;
}

function displayIsCharging(isCharging) {
  switch (isCharging) {
    case 0:
      return 'no';
    case 1:
      return 'yes';
    default:
      return `unknown (${isCharging})`;
  }
}

function displaySystemEvent(systemEvent) {
  switch (systemEvent) {
    case 0:
      return 'none';
    case 1:
      return 'boot';
    case 2:
      return 'BLE connect';
    case 3:
      return 'BLE disconnect';
    case 4:
      return 'capa calibration';
    case 5:
      return 'imu calibration';
    default:
      return `unknown (${systemEvent})`;
  }
}

function TooltipInfoEventTrace({ event }) {
  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
      <Grid item>
        <Typography>Trace event</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{ `side: ${event.side}` }</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">
          { `connectionType: ${displayConnectionType(event.connectionType)}` }
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">
          { `powerLevel: ${displayPowerLevel(event.powerLevel)}` }
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">
          { `voltage: ${displayVoltage(event.voltage)} V` }
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">
          { `is charging? ${displayIsCharging(event.isCharging)}` }
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">
          { `system event: ${displaySystemEvent(event.systemEvent)}` }
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">
          { `stride counter: ${event.strideCounter}` }
        </Typography>
      </Grid>
    </Grid>
  );
}

TooltipInfoEventTrace.propTypes = {
  event: PropTypes.shape({
    side: PropTypes.oneOf(['left', 'right']),
    connectionType: PropTypes.number,
    powerLevel: PropTypes.number,
    voltage: PropTypes.number,
    isCharging: PropTypes.number,
    systemEvent: PropTypes.number,
    strideCounter: PropTypes.number,
  }).isRequired,
};

export default TooltipInfoEventTrace;
