import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';
import ProgressWait from '../components/display/progress-wait';

import RecordItem from '../components/record/index';
import RecordMetricsInfo from './components/record-metrics-info';
import RecordRawInfo from './components/record-raw-info';
import RecordRaw16Info from './components/record-raw16-info';

import { fetchRecord } from '../firebase/store';

function canShowMetricInfo(record) {
  return (record.stopTime && ((record.type === 'dios'
    && (record.counterLeftStride > 0 || record.counterRightStride > 0))
  || (record.type === 'rehab' && record.recordType === 'walkingtest')
  || (record.type === 'dynamic')));
}

function canShowRawInfo(record) {
  return (record.stopTime && record.type === 'capa-imu'
    && (record.counterLeftCapa > 0 || record.counterLeftIMU > 0
      || record.counterRightCapa > 0 || record.counterRightIMU > 0));
}

function canShowRaw16Info(record) {
  return (record.stopTime && record.type === 'capa16-imu'
    && (record.counterLeftCapa > 0 || record.counterLeftIMU > 0
      || record.counterRightCapa > 0 || record.counterRightIMU > 0));
}

function Record(props) {
  const [record, setRecord] = useState(undefined);
  const [loadingRecord, setLoadingRecord] = useState(true);

  useEffect(() => {
    fetchRecord(props.match.params.recordID)
      .then((i) => {
        setRecord(i);
        setLoadingRecord(false);
      });
  }, []);

  let child;
  if (loadingRecord) {
    child = (<ProgressWait marginTop={40} />);
  } else if (record === undefined) {
    child = (
      <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
        <Grid item>
          <ErrorIcon color="primary" />
        </Grid>
        <Grid item>
          <Typography>
            Record not found
          </Typography>
        </Grid>
      </Grid>
    );
  } else {
    child = (
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
        <Grid item>
          <RecordItem record={record} directLink />
        </Grid>
        <Grid item>
          { canShowMetricInfo(record) && (
            <RecordMetricsInfo record={record} recordId={record.key} />
          )}
          { canShowRawInfo(record) && (
            <RecordRawInfo record={record} recordId={record.key} />
          )}
          { canShowRaw16Info(record) && (
            <RecordRaw16Info record={record} recordId={record.key} />
          )}
        </Grid>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <PageHeader>
        <PageTitle>Record</PageTitle>
      </PageHeader>
      { child }
    </React.Fragment>
  );
}

Record.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      recordID: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Record;
