import React from 'react';
import {
  Grid,
} from '@material-ui/core/';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';

import Create from './components/create';
import List from './components/list';

import { isGroupAdmin } from '../firebase/store';
import { userID } from '../firebase/auth';

class Organisations extends React.Component {
  constructor() {
    super();

    this.state = {
      isGroupAdmin: false,
    };
  }

  componentDidMount() {
    isGroupAdmin(userID()).then(res => this.setState({ isGroupAdmin: res }));
  }

  render() {
    return (
      <div>
        <PageHeader>
          <PageTitle>Organisations</PageTitle>
        </PageHeader>
        <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
          { this.state.isGroupAdmin && (
            <Grid item>
              <Create />
            </Grid>
          )}
          <Grid item>
            <List />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Organisations;
