import React from 'react';
import PropTypes from 'prop-types';
import { extent } from 'd3-array';
import { curveMonotoneX } from 'd3-shape';
import { scaleLinear } from 'd3-scale';

import {
  Path,
  Axis,
  useGraphDimensionsContext,
  accessorPropType,
} from '@feetme/d3act';

import { getColorLeftSide, getColorRightSide } from '../../utils/colors';

function TimelineRecordRssi({ data, xAccessor, noAxis }) {
  const dimensions = useGraphDimensionsContext();

  const yAccessor = d => d.y;

  const yScale = scaleLinear()
    .domain(extent(data, yAccessor))
    .range([0, dimensions.boundedHeight]);

  const yAccessorScaled = d => yScale(yAccessor(d));

  return (
    <React.Fragment>
      <g>
        <Path
          data={data.filter(i => i.side === 'left')}
          xAccessor={xAccessor}
          yAccessor={yAccessorScaled}
          interpolation={curveMonotoneX}
          stroke={getColorLeftSide()}
          opacity={0.2}
          strokeWidth={2}
        />
        <Path
          data={data.filter(i => i.side === 'right')}
          xAccessor={xAccessor}
          yAccessor={yAccessorScaled}
          interpolation={curveMonotoneX}
          stroke={getColorRightSide()}
          opacity={0.2}
          strokeWidth={2}
        />
      </g>
      { !noAxis && (
        <Axis
          dimension="y"
          scale={yScale}
          keyAccessor={d => d}
          reverse
        />
      )}
    </React.Fragment>
  );
}

TimelineRecordRssi.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    side: PropTypes.string,
  })).isRequired,
  xAccessor: accessorPropType.isRequired,
  noAxis: PropTypes.bool,
};

TimelineRecordRssi.defaultProps = {
  noAxis: false,
};

export default TimelineRecordRssi;
