import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function RecordRehab(props) {
  return (
    <div>
      <Typography variant="subtitle1">
        Info rehab
      </Typography>
      <Typography>
        {`Success: ${props.record.successPercentage}%`}
      </Typography>
    </div>
  );
}

RecordRehab.propTypes = {
  record: PropTypes.shape({
    successPercentage: PropTypes.number,
  }).isRequired,
};

export default RecordRehab;
