import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import BlockIcon from '@material-ui/icons/Block';
import { withStyles } from '@material-ui/core/styles';
import streamsaver from 'streamsaver';
import { WritableStream } from 'web-streams-polyfill/ponyfill';

import { getPalios } from '../../utils/url';

const SIZE = 32;

const styles = () => ({
  anchor: {
    display: 'none',
  },
  icon: {
    fontSize: SIZE,
  },
});

class RecordDownload extends React.Component {
  constructor(props) {
    super();

    this.state = {
      fetching: false,
      blocked: false,
    };

    // fallback to recordId if there is no tag in the record
    if (props.tagRecord === undefined || props.tagRecord.length === 0) {
      this.filename = `${props.type}-${props.recordID}.csv`;
    } else {
      this.filename = `${props.type}-${props.tagRecord}.csv`;
    }

    this.handleClick = this.handleClick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state.fetching !== nextState.fetching || this.state.blocked !== nextState.blocked);
  }

  componentWillUnmount() {
    if (this.writer) {
      this.writer.abort();
    }
  }

  handleClick() {
    this.setState({ fetching: true });
    if (this.props.type === 'metrics') {
      streamsaver.WritableStream = WritableStream;
      streamsaver.mitm = `${getPalios()}/streamsaver/mitm.html`;
      const stream = streamsaver.createWriteStream(this.filename);
      this.writer = stream.getWriter();
      this.props.downloadFn(this.props.recordID, this.writer)
        .then(() => {
          this.writer.close();
          this.setState({ fetching: false });
        });
    } else {
      this.props.downloadFn(this.props.recordID)
        .then((csvString) => {
          const blob = new Blob([csvString]);
          if (this.node) {
            this.node.href = URL.createObjectURL(blob);
            this.node.click();
          }
          this.setState({ fetching: false });
        })
        .catch(() => {
          this.setState({ blocked: true });
          // reset button after 2 seconds
          setTimeout(() => this.setState({ blocked: false, fetching: false }), 2000);
        });
    }
  }

  getButtonDisplay() {
    if (this.state.blocked) {
      return <BlockIcon className={this.props.classes.icon} color="primary" />;
    }

    if (this.state.fetching) {
      return <CircularProgress size={SIZE} />;
    }
    return (
      <Button onClick={this.handleClick}>
        {`Download ${this.props.type}`}
      </Button>
    );
  }

  render() {
    return (
      <div>
        <a
          download={this.filename}
          type="text/csv"
          className={this.props.classes.anchor}
          href="/"
          ref={(node) => {
            this.node = node;
            return undefined;
          }}
        >
          {`Download ${this.props.type}`}
        </a>
        { this.getButtonDisplay() }
      </div>
    );
  }
}

RecordDownload.propTypes = {
  type: PropTypes.string.isRequired,
  recordID: PropTypes.string.isRequired,
  tagRecord: PropTypes.string,
  downloadFn: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

RecordDownload.defaultProps = {
  tagRecord: undefined,
};

export default withStyles(styles)(RecordDownload);
