import { fetchBatchStrides } from './store';

async function streamStrides(recordId, batchSize, callback) {
  let lastStrideClientTimestamp;
  let hasStillStrides = true;

  do {
    // eslint-disable-next-line no-await-in-loop
    const strides = await fetchBatchStrides(recordId, batchSize, lastStrideClientTimestamp);
    if (strides.length > 0) {
      lastStrideClientTimestamp = strides[strides.length - 1].clientTimestamp;
    }

    if (strides.length < batchSize) {
      hasStillStrides = false;
    }

    strides.forEach(stride => callback(stride));
  } while (hasStillStrides);
}

export {
  // eslint-disable-next-line import/prefer-default-export
  streamStrides,
};
