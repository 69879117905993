import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';

const styles = {
  value: {
    borderBottom: '2px dotted #000',
  },
};

function RecordFlag(props) {
  let tooltipStr = '';

  if (props.minThreshold !== Number.MIN_SAFE_INTEGER) {
    tooltipStr += `${props.minThreshold}${props.unit} < `;
  }

  if (props.maxThreshold !== Number.MAX_SAFE_INTEGER
    || props.minThreshold !== Number.MIN_SAFE_INTEGER) {
    tooltipStr += 'x';
  }

  if (props.maxThreshold !== Number.MAX_SAFE_INTEGER) {
    tooltipStr += ` < ${props.maxThreshold}${props.unit}`;
  }

  return (
    <Grid container direction="row" jusfity="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <Typography>
          {`${props.name}:`}
        </Typography>
      </Grid>
      <Grid item>
        <Tooltip title={tooltipStr} placement="top">
          <Typography
            variant="subtitle2"
            color={(props.value >= props.maxThreshold || props.value <= props.minThreshold) ? 'error' : 'initial'}
            style={tooltipStr.length !== 0 ? styles.value : {}}
          >
            {`${props.value}${props.unit}`}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

RecordFlag.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  unit: PropTypes.string,
  maxThreshold: PropTypes.number,
  minThreshold: PropTypes.number,
};

RecordFlag.defaultProps = {
  maxThreshold: Number.MAX_SAFE_INTEGER,
  minThreshold: Number.MIN_SAFE_INTEGER,
  unit: '',
};

export default RecordFlag;
