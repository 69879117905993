import {
  UPDATE_QUERY_APPLICATION_TYPE,
  UPDATE_QUERY_LIMIT_FETCH,
  UPDATE_QUERY_AUTHOR_ID,
  UPDATE_QUERY_ORGANISATION_ID,
  UPDATE_QUERY_CONNECTION_TYPE,
  UPDATE_QUERY_RECORD_TYPE,
  UPDATE_QUERY_BEFORE_DATE,
  UPDATE_QUERY_INSOLE_MAC,
  UPDATE_QUERY_INSOLE_NUMBER,
  UPDATE_QUERY_INSOLE_SIZE,
  UPDATE_QUERY_INSOLE_SIDE,
  UPDATE_QUERY_INSOLE_VERSION_NORDIC,
  UPDATE_QUERY_INSOLE_VERSION_ATMEL,

  UPDATE_QUERYS,
} from '../actions/types';

const DEFAULT_QUERY = {
  applicationType: 'all',
  // will be set on login
  authorId: '',
  organisationId: 'all',
  limitFetch: '10',
  connectionType: 'dios',
  recordType: 'all',
  beforeDate: 'all',

  macAddress: 'all',
  number: 'all',
  side: 'all',
  size: 'all',
  versionNordic: 'all',
  versionAtmel: 'all',
};

function query(state = DEFAULT_QUERY, action) {
  switch (action.type) {
    case UPDATE_QUERY_APPLICATION_TYPE:
      return { ...state, applicationType: action.applicationType };
    case UPDATE_QUERY_AUTHOR_ID:
      return { ...state, authorId: action.authorId };
    case UPDATE_QUERY_ORGANISATION_ID:
      return { ...state, organisationId: action.organisationId };
    case UPDATE_QUERY_LIMIT_FETCH:
      return { ...state, limitFetch: action.limitFetch };
    case UPDATE_QUERY_CONNECTION_TYPE:
      return { ...state, connectionType: action.connectionType };
    case UPDATE_QUERY_RECORD_TYPE:
      return { ...state, recordType: action.recordType };
    case UPDATE_QUERY_BEFORE_DATE:
      return { ...state, beforeDate: action.beforeDate };
    case UPDATE_QUERY_INSOLE_MAC:
      return { ...state, macAddress: action.macAddress };
    case UPDATE_QUERY_INSOLE_NUMBER:
      return { ...state, number: action.number };
    case UPDATE_QUERY_INSOLE_SIDE:
      return { ...state, side: action.side };
    case UPDATE_QUERY_INSOLE_SIZE:
      return { ...state, size: action.size };
    case UPDATE_QUERY_INSOLE_VERSION_NORDIC:
      return { ...state, versionNordic: action.versionNordic };
    case UPDATE_QUERY_INSOLE_VERSION_ATMEL:
      return { ...state, versionAtmel: action.versionAtmel };
    case UPDATE_QUERYS:
      return {
        ...state,
        limitFetch: action.query.limitFetch,
        authorId: action.query.authorId,
        applicationType: action.query.applicationType,
        connectionType: action.query.connectionType,
        recordType: action.query.recordType,
        beforeDate: action.query.beforeDate,
      };
    default:
      return state;
  }
}

export default query;
