import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import QueryFieldToggle from '../../components/display/query-field-toggle';

import { updateQueryInsoleNumber } from '../../actions/index';

function mapStateToProps(state) {
  return ({
    number: state.query.number,
  });
}

function QueryInsoleNumber({ number, dispatch }) {
  return (
    <QueryFieldToggle
      value={number}
      switchValue="insoleNumber"
      switchLabel="All insole numbers"
      onSwitchChange={ev => dispatch(updateQueryInsoleNumber(ev.target.checkde ? 'all' : '0'))}
      textLabel="Number"
      onTextChange={ev => dispatch(updateQueryInsoleNumber(ev.target.value))}
    />
  );
}

QueryInsoleNumber.propTypes = {
  number: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(QueryInsoleNumber);
