import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { select } from 'd3-selection';
import { brushX } from 'd3-brush';
import { extent } from 'd3-array';
import { scaleTime } from 'd3-scale';
import { timeFormat } from 'd3-time-format';

import { Graph, Axis, useGraphDimensions } from '@feetme/d3act';

import TimelineRecordRssi from './timeline-record-rssi';
import TimelineRecordStrides from './timeline-record-strides';
import RectDisconnections from './rect-disconnections';
import LineTimesRecord from './line-times-record';
import TimelineRecordEvents from './timeline-record-events';
import Tooltip from './tooltip';
import TooltipInfoEventTrace from './tooltip-info-event-trace';
import TooltipInfoEventIntercom from './tooltip-info-event-intercom';
import TooltipInfoEventGravityVector from './tooltip-info-event-gravity-vector';

const useStyles = makeStyles({
  root: {
    height: '750px',
    width: '100%',
    position: 'relative',
  },
  rootBrush: {
    height: '200px',
    width: '100%',
  },
});

const formatTime = timeFormat('%e %b %Y %H:%M:%S');

function getDefaultxScale(dimensions) {
  return scaleTime().range([0, dimensions.boundedWidth]);
}

function TimelineRecord(props) {
  const {
    strides,
    rssi,
    disconnections,
    events,
    startTime,
    stopTime,
  } = props;

  // Tooltip state
  const [tooltipOpacity, setTooltipOpacity] = useState(0);
  const [tooltipTransform, setTooltipTransform] = useState('');
  const [tooltipType, setTooltipType] = useState('');
  const [tooltipData, setTooltipData] = useState({});

  const [ref, dimensions] = useGraphDimensions();
  const [refBrush, dimensionsBrush] = useGraphDimensions();
  const graphRef = useRef(null);
  const classes = useStyles();

  const xAccessor = d => new Date(d.x);
  const defaultDomain = extent([].concat(
    strides,
    rssi,
    disconnections.reduce((acc, cur) => {
      acc.push(cur.x1, cur.x2);
      return acc;
    }, []),
    [{ x: startTime }, { x: stopTime }],
  ), xAccessor);

  // need to store in state to redraw on update
  const [xDomain, setxDomain] = useState(defaultDomain);

  const xScale = getDefaultxScale(dimensions).domain(xDomain);
  const xScaleFixed = getDefaultxScale(dimensions).domain(defaultDomain);

  const xAccessorScaled = d => xScale(xAccessor(d));
  const xAccessorScaledFixed = d => xScaleFixed(xAccessor(d));

  useEffect(() => {
    const brush = brushX()
      .extent([[0, 0], [dimensionsBrush.boundedWidth, dimensionsBrush.boundedHeight]])
      .on('end', (event) => {
        const { selection } = event;
        if (!selection) {
          setxDomain(defaultDomain);
        } else {
          setxDomain([xScaleFixed.invert(selection[0]), xScaleFixed.invert(selection[1])]);
        }
      });

    // XXX we shouldn't have to used d3.select & d3.call in a react code
    select(graphRef.current).call(brush);
  });

  function updateTooltip(tooltipConfig) {
    Object.keys(tooltipConfig).forEach((key) => {
      if (key === 'opacity') {
        setTooltipOpacity(tooltipConfig[key]);
      } else if (key === 'transform') {
        setTooltipTransform(tooltipConfig[key]);
      } else if (key === 'type') {
        setTooltipType(tooltipConfig[key]);
      } else if (key === 'data') {
        setTooltipData(tooltipConfig[key]);
      } else {
        // eslint-disable-next-line no-console
        console.error(`Unknown tooltip config key: ${key}`);
      }
    });
  }

  // refactor the two graphs
  // we could add a box to hide data that goes out of bound when the brush is used
  return (
    <React.Fragment>
      <div className={classes.root} ref={ref}>
        <Tooltip
          opacity={tooltipOpacity}
          transform={tooltipTransform}
        >
          { tooltipType === 'trace-event' && (
            <TooltipInfoEventTrace event={tooltipData} />
          )}
          { tooltipType === 'intercom-event' && (
            <TooltipInfoEventIntercom event={tooltipData} />
          )}
          { tooltipType === 'gravityVector-event' && (
            <TooltipInfoEventGravityVector event={tooltipData} />
          )}
        </Tooltip>
        <Graph dimensions={dimensions}>
          <Axis dimension="x" scale={xScale} formatTick={formatTime} keyAccessor={d => d.getTime()} />
          <TimelineRecordRssi data={rssi} xAccessor={xAccessorScaled} />
          <TimelineRecordStrides data={strides} xAccessor={xAccessorScaled} />
          <RectDisconnections data={disconnections} xScale={xScale} />
          <LineTimesRecord data={[startTime, stopTime]} xScale={xScale} />
          <TimelineRecordEvents data={events} xScale={xScale} updateTooltip={updateTooltip} />
        </Graph>
      </div>
      <div className={classes.rootBrush} ref={refBrush}>
        <Graph dimensions={dimensionsBrush}>
          <Axis dimension="x" scale={xScaleFixed} formatTick={formatTime} keyAccessor={d => d.getTime()} />
          <g ref={graphRef}>
            <TimelineRecordRssi noAxis data={rssi} xAccessor={xAccessorScaledFixed} />
            <TimelineRecordStrides noAxis data={strides} xAccessor={xAccessorScaledFixed} />
            <RectDisconnections data={disconnections} xScale={xScaleFixed} />
            <LineTimesRecord data={[startTime, stopTime]} xScale={xScaleFixed} />
            <TimelineRecordEvents data={events} xScale={xScaleFixed} />
          </g>
        </Graph>
      </div>
    </React.Fragment>
  );
}

TimelineRecord.propTypes = {
  strides: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    side: PropTypes.string,
  })).isRequired,
  rssi: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    side: PropTypes.string,
  })).isRequired,
  disconnections: PropTypes.arrayOf(PropTypes.shape({
    x1: PropTypes.number,
    x2: PropTypes.number,
    side: PropTypes.string,
  })).isRequired,
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  startTime: PropTypes.number.isRequired,
  stopTime: PropTypes.number.isRequired,
};

export default TimelineRecord;
