import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import QueryFieldToggle from '../../components/display/query-field-toggle';

import { updateQueryInsoleVersionAtmel } from '../../actions/index';

function mapStateToProps(state) {
  return ({
    versionAtmel: state.query.versionAtmel,
  });
}

function QueryInsoleVersionAtmel({ versionAtmel, dispatch }) {
  return (
    <QueryFieldToggle
      value={versionAtmel}
      switchValue="versionAtmel"
      switchLabel="All atmel versions"
      onSwitchChange={ev => dispatch(updateQueryInsoleVersionAtmel(ev.target.checked ? 'all' : '1.0.0'))}
      textLabel="Version Atmel"
      onTextChange={ev => dispatch(updateQueryInsoleVersionAtmel(ev.target.value))}
    />
  );
}

QueryInsoleVersionAtmel.propTypes = {
  versionAtmel: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(QueryInsoleVersionAtmel);
