import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Switch,
  TextField,
  FormControlLabel,
} from '@material-ui/core/';

function QueryFieldToggle(props) {
  const {
    value,
    switchValue,
    switchLabel,
    onSwitchChange,
    textLabel,
    onTextChange,
  } = props;

  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={4}>
      <Grid item>
        <FormControlLabel
          control={(
            <Switch
              checked={value === 'all'}
              onChange={onSwitchChange}
              value={switchValue}
            />
          )}
          label={switchLabel}
        />
      </Grid>
      <Grid item>
        <TextField
          label={textLabel}
          value={value}
          onChange={onTextChange}
        />
      </Grid>
    </Grid>
  );
}

QueryFieldToggle.propTypes = {
  value: PropTypes.string.isRequired,
  switchValue: PropTypes.string.isRequired,
  switchLabel: PropTypes.string.isRequired,
  onSwitchChange: PropTypes.func.isRequired,
  textLabel: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
};

export default QueryFieldToggle;
