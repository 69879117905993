import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { extent } from 'd3-array';
import { scaleLinear, scaleTime } from 'd3-scale';
import { timeFormat } from 'd3-time-format';

import {
  Graph,
  Axis,
  Path,
  Circles,
  useGraphDimensions,
} from '@feetme/d3act';

const formatDate = timeFormat('%Y-%m-%d');

const useStyles = makeStyles({
  root: {
    height: '500px',
    width: '100%',
  },
});

function TimelineAllRecords({ data }) {
  const [ref, dimensions] = useGraphDimensions();
  const classes = useStyles();
  const theme = useTheme();

  const xAccessor = d => d.x;
  const yAccessor = d => d.y;

  const xScale = scaleTime()
    .domain(extent(data, xAccessor))
    .range([0, dimensions.boundedWidth]);

  const yScale = scaleLinear()
    .domain(extent(data, yAccessor))
    .range([dimensions.boundedHeight, 0]);

  const xAccessorScaled = d => xScale(xAccessor(d));
  const yAccessorScaled = d => yScale(yAccessor(d));

  return (
    <div className={classes.root} ref={ref}>
      <Graph dimensions={dimensions}>
        <Axis
          dimension="x"
          scale={xScale}
          formatTick={formatDate}
          keyAccessor={d => d}
        />
        <Axis
          dimension="y"
          scale={yScale}
          fullTick
          keyAccessor={d => d}
        />
        <Path
          data={data}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          stroke={theme.palette.secondary.main}
          strokeWidth={2}
        />
        <Circles
          data={data}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          keyAccessor={d => `${d.x}${d.y}`}
          fill={theme.palette.secondary.main}
        />
      </Graph>
    </div>
  );
}

TimelineAllRecords.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.instanceOf(Date),
    y: PropTypes.number,
  })).isRequired,
};

export default TimelineAllRecords;
