import React from 'react';
import PropTypes from 'prop-types';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';
import ProgressWait from '../components/display/progress-wait';

import OrganisationItem from '../components/organisation/index';
import OrganisationLoadRecordsWrapper from './components/load-records-wrapper';

import { fetchOrga } from '../firebase/store';

class Organisation extends React.Component {
  constructor() {
    super();

    this.state = {
      organisation: undefined,
    };
  }

  componentDidMount() {
    fetchOrga(this.props.match.params.orgaId)
      .then(organisation => this.setState({ organisation }));
  }

  render() {
    return (
      <React.Fragment>
        <PageHeader>
          <PageTitle>Organisation</PageTitle>
        </PageHeader>
        { this.state.organisation === undefined
          ? <ProgressWait marginTop={40} />
          : (
            <div>
              <OrganisationItem organisation={this.state.organisation} directLink />
              <OrganisationLoadRecordsWrapper orgaId={this.state.organisation.key} />
            </div>
          )}
      </React.Fragment>
    );
  }
}

Organisation.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orgaId: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Organisation;
