import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import * as Sentry from '@sentry/browser';

import { signOut } from '../../firebase/auth';
import { flush } from '../../actions/index';

class SignOut extends React.Component {
  constructor() {
    super();

    this.state = {
      userAsSignedOut: false,
    };

    this.handleSignOut = this.handleSignOut.bind(this);
  }

  handleSignOut() {
    signOut()
      .then(() => {
        // clear any Sentry scope
        Sentry.configureScope(scope => scope.clear());
        this.props.dispatch(flush());
        this.setState({ userAsSignedOut: true });
      });
  }

  render() {
    const res = this.state.userAsSignedOut ? (
      <Redirect to="/login" />
    ) : (
      <Button variant="contained" onClick={this.handleSignOut}>
        Sign out
      </Button>
    );

    return res;
  }
}

SignOut.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(SignOut);
