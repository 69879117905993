// TODO: we should merge this function with the one in the dial project
// probably in a node module (one for all the metrics)
function distance(strides) {
  const data = strides.map(i => ({
    x: i.x,
    y: i.velocity,
  }));

  let startTime;
  let numberStrides = 0;
  let sumVelocity = 0;
  let fullDistance = 0;
  let previousStrideTime = 0;
  let distanceIntervalWithoutCurrentStride = 0;

  data.forEach((i, index) => {
    if (numberStrides === 0) {
      startTime = i.x;
      previousStrideTime = i.x;
    }
    const endTime = i.x;

    if ((endTime - previousStrideTime) > (10 * 1000)) {
      fullDistance += distanceIntervalWithoutCurrentStride;
      startTime = 0;
      sumVelocity = 0;
      numberStrides = 0;
      previousStrideTime = 0;
      distanceIntervalWithoutCurrentStride = 0;
    } else {
      previousStrideTime = endTime;
      sumVelocity += i.y;
      numberStrides += 1;

      const distanceCurrentInterval = (sumVelocity / numberStrides)
        * ((endTime - startTime) / 1000);
      distanceIntervalWithoutCurrentStride = distanceCurrentInterval;

      if (distanceCurrentInterval >= 5) {
        fullDistance += distanceCurrentInterval;
        startTime = endTime;
        sumVelocity = i.y;
        numberStrides = 1;
        previousStrideTime = startTime;
        distanceIntervalWithoutCurrentStride = 0;
      } else if (index === data.length - 1 && numberStrides > 1) {
        fullDistance += distanceCurrentInterval;
      }
    }
  });

  return fullDistance;
}

function distancePerStrides() {
  let startTime;
  let numberStrides = 0;
  let sumVelocity = 0;
  let fullDistance = 0;
  let previousStrideTime = 0;
  let distanceIntervalWithoutCurrentStride = 0;

  return ((stride) => {
    if (numberStrides === 0) {
      startTime = stride.clientTimestamp;
      previousStrideTime = stride.clientTimestamp;
    }

    const endTime = stride.clientTimestamp;

    if ((endTime - previousStrideTime) > (10 * 1000)) {
      fullDistance += distanceIntervalWithoutCurrentStride;
      startTime = 0;
      sumVelocity = 0;
      numberStrides = 0;
      previousStrideTime = 0;
      distanceIntervalWithoutCurrentStride = 0;
    } else {
      previousStrideTime = endTime;
      sumVelocity += stride.velocity;
      numberStrides += 1;

      const distanceCurrentInterval = (sumVelocity / numberStrides)
        * ((endTime - startTime) / 1000);
      distanceIntervalWithoutCurrentStride = distanceCurrentInterval;

      if (distanceCurrentInterval >= 5) {
        fullDistance += distanceCurrentInterval;
        startTime = endTime;
        sumVelocity = stride.velocity;
        numberStrides = 1;
        previousStrideTime = startTime;
        distanceIntervalWithoutCurrentStride = 0;
      } else {
        distanceIntervalWithoutCurrentStride = distanceCurrentInterval;
      }
    }

    return fullDistance + distanceIntervalWithoutCurrentStride;
  });
}

export {
  distance,
  distancePerStrides,
};
