import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

function DrawerLink(props) {
  return (
    <ListItem
      button
      component={props.href ? 'a' : Link}
      to={props.to}
      href={props.to}
      target={props.href ? '_blank' : ''}
      rel={props.href ? 'noopener noreferrer' : ''}
      onClick={props.onClick}
    >
      <ListItemIcon>
        { props.children }
      </ListItemIcon>
      <ListItemText primary={props.text} />
    </ListItem>
  );
}

DrawerLink.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  href: PropTypes.bool,
};

DrawerLink.defaultProps = {
  href: false,
};

export default DrawerLink;
