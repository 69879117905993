import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

function TooltipInfoEventGravityVector({ event }) {
  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
      <Grid item>
        <Typography>Gravity vector event</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{ `side: ${event.side}` }</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{ `x: ${event.x}` }</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{ `y: ${event.y}` }</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{ `z: ${event.z}` }</Typography>
      </Grid>
    </Grid>
  );
}

TooltipInfoEventGravityVector.propTypes = {
  event: PropTypes.shape({
    side: PropTypes.oneOf(['left', 'right']),
    x: PropTypes.number,
    y: PropTypes.number,
    z: PropTypes.number,
  }).isRequired,
};

export default TooltipInfoEventGravityVector;
