import { combineReducers } from 'redux';

import { FLUSH } from '../actions/types';
import records from './records';
import query from './query';

const appReducer = combineReducers({
  records,
  query,
});

function rootReducers(state, action) {
  if (action.type === FLUSH) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}

export default rootReducers;
