import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateQueryApplicationType, updateQueryConnectionType } from '../../actions/index';
import SimpleSelect from '../../components/selection-controls/simple-select';

function mapStateToProps(state) {
  return ({
    applicationType: state.query.applicationType,
  });
}

class QueryApplicationType extends React.Component {
  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    if (this.props.applicationType === 'android-rehab') {
      this.props.dispatch(updateQueryConnectionType('rehab'));
    } else if (this.props.applicationType === 'android-lokara') {
      this.props.dispatch(updateQueryConnectionType('dios'));
    } else if (this.props.applicationType === 'ios-rehab') {
      this.props.dispatch(updateQueryConnectionType('rehab'));
    }
  }

  handleChange(ev) {
    this.props.dispatch(updateQueryApplicationType(ev.target.value));
  }

  render() {
    return (
      <SimpleSelect
        id="application-select"
        title="Application type"
        value={this.props.applicationType}
        options={[{
          key: 'all',
          label: 'All',
        }, {
          key: 'android-dashboard',
          label: 'Android Dashboard',
        }, {
          key: 'android-lokara',
          label: 'Android Evaluation',
        }, {
          key: 'android-rehab',
          label: 'Android Rehab',
        }, {
          key: 'ios-rehab',
          label: 'iOS Rehab',
        }]}
        onChange={this.handleChange}
      />
    );
  }
}

QueryApplicationType.propTypes = {
  applicationType: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(QueryApplicationType);
