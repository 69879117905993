import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { timeDifferenceStrict } from '../../utils/time';

function RecordDuration(props) {
  return (
    <div>
      <Typography variant="subtitle1">
        Times
      </Typography>
      <Typography>
        {`Start time: ${props.startTime}`}
      </Typography>
      {
        props.stopTime && (
          <div>
            <Typography>
              {`Stop time: ${props.stopTime}`}
            </Typography>
            <Typography>
              {`Duration: ${timeDifferenceStrict(props.startTime, props.stopTime)}`}
            </Typography>
          </div>
        )
      }
    </div>
  );
}

RecordDuration.propTypes = {
  startTime: PropTypes.number.isRequired,
  stopTime: PropTypes.number,
};

RecordDuration.defaultProps = {
  stopTime: undefined,
};

export default RecordDuration;
