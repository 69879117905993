import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

import Checkboxes from '../../components/selection-controls/checkboxes';
import Radios from '../../components/selection-controls/radios';
import ProgressWait from '../../components/display/progress-wait';
import TimelineRecord from './timeline-record';

import {
  filterRssi,
  cleanDisconnections,
  filterDisconnections,
  filterEvents,
} from '../../utils/record';
import {
  fetchConnectionTimestamp,
  fetchRSSI,
  fetchStrides,
  fetchEvents,
} from '../../firebase/store';
import { SIDES } from '../../utils/sides';
import { RSSI } from '../../utils/rssi';
import { DISCONNECTIONS } from '../../utils/disconnections';

const ALGO_FLAGS = [{
  key: 0,
  label: 'Default',
}, {
  key: 1,
  label: 'Micro step',
}, {
  key: 2,
  label: 'Weight transfer',
}, {
  key: 3,
  label: 'Inactivity',
}, {
  key: 4,
  label: 'Uncertainty',
}, {
  key: 0xff,
  label: 'Unknown',
}];

const SYSTEM_FLAGS = [{
  key: 'none',
  label: 'None',
}, {
  key: 'overflow',
  label: 'Data processing overflow',
}, {
  key: 'rtcOscillator',
  label: 'RTC Oscillator stop',
}, {
  key: 'dtOverflow',
  label: 'dt overflow',
}, {
  key: 'corruption',
  label: 'Corruption',
}];

const METRICS = [{
  key: 'strideLength',
  label: 'Stride length',
}, {
  key: 'velocity',
  label: 'Velocity',
}, {
  key: 'strideDuration',
  label: 'Stride duration',
}];

const EVENTS = [{
  key: 'trace',
  label: 'Trace event',
}, {
  key: 'intercom',
  label: 'Intercom event',
}, {
  key: 'gravityVector',
  label: 'Gravity vector event',
}];

function RecordMetricsGraphWrapper({ recordId, record }) {
  const [dataLoading, setDataLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  // parameters
  const [sides, setSides] = useState(SIDES.map(side => side.key));
  const [algoFlags, setAlgoFlags] = useState(ALGO_FLAGS.map(flag => `${flag.key}`));
  const [systemFlags, setSystemFlags] = useState(SYSTEM_FLAGS.map(flag => `${flag.key}`));
  const [eventTypes, setEventTypes] = useState(EVENTS.map(event => `${event.key}`));
  const [rssiSide, setRSSISide] = useState(RSSI.map(i => i.key));
  const [metric, setMetric] = useState('strideLength');
  const [disconnectionsSide, setDisconnectionsSide] = useState(DISCONNECTIONS.map(i => i.key));

  // data
  const [strides, setStrides] = useState([]);
  const [rssi, setRSSI] = useState([]);
  const [connectionTimestamps, setConnectionTimestamps] = useState([]);
  const [events, setEvents] = useState([]);

  function handleCheckboxes(cb) {
    return (key, ev) => {
      if (ev.target.checked) {
        cb(prevState => [...prevState, key]);
      } else {
        cb(prevState => prevState.filter(i => i !== key));
      }
    };
  }

  function handleRadios(cb) {
    return ev => cb(ev.target.value);
  }

  function filterStrides() {
    return strides
      .filter(stride => algoFlags.indexOf(`${stride.flag}`) > -1 && sides.indexOf(stride.side) > -1)
      .filter(stride => systemFlags.some(flag => stride[flag] === true) || (systemFlags.indexOf('none') > -1 && SYSTEM_FLAGS.map(i => i.key).every(flag => stride[flag] !== true)))
      .map(stride => ({
        x: stride.x,
        y: stride[metric],
        side: stride.side,
      }));
  }

  async function loadData() {
    setDataLoading(true);

    setConnectionTimestamps(cleanDisconnections(
      await fetchConnectionTimestamp(recordId),
      record.startTime,
      record.stopTime,
    ));
    setRSSI(await fetchRSSI(recordId));
    setStrides((await fetchStrides(recordId)).map(stride => ({
      x: stride.clientTimestamp,
      timestamp: stride.timestamp,
      side: stride.side,
      strideLength: stride.strideLength,
      velocity: stride.velocity,
      strideDuration: stride.strideDuration,
      capa: stride.capa,
      flag: stride.flag,
      overflow: stride.overflow,
      rtcOscillator: stride.rtcOscillator,
      dtOverflow: stride.dtOverflow,
      corruption: stride.corruption,
    })));
    setEvents(await fetchEvents(recordId));

    setDataLoaded(true);
    setDataLoading(false);
  }

  if (dataLoading) {
    return (<ProgressWait />);
  }

  if (dataLoaded === false) {
    return (
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={loadData}
            startIcon={((record.counterLeftStride || 0) + (record.counterRightStride || 0) > 10000)
              ? <WarningIcon />
              : undefined}
          >
            Load graph
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Card>
      <CardHeader title="Metric display" />
      <CardContent>
        <Grid container direction="row" justify="space-around" alignItems="flex-start">
          <Grid item>
            <Checkboxes
              title="Sides selection"
              options={SIDES.map(({ key, label }) => ({
                key,
                checked: sides.indexOf(key) !== -1,
                label,
              }))}
              handleChange={handleCheckboxes(setSides)}
            />
          </Grid>
          <Grid item>
            <Checkboxes
              title="Algo flags selection"
              options={ALGO_FLAGS.map(({ key, label }) => ({
                key: `${key}`,
                checked: algoFlags.indexOf(`${key}`) !== -1,
                label,
              }))}
              handleChange={handleCheckboxes(setAlgoFlags)}
            />
          </Grid>
          <Grid item>
            <Checkboxes
              title="System flags selection"
              options={SYSTEM_FLAGS.map(({ key, label }) => ({
                key: `${key}`,
                checked: systemFlags.indexOf(`${key}`) !== -1,
                label,
              }))}
              handleChange={handleCheckboxes(setSystemFlags)}
            />
          </Grid>
          <Grid item>
            <Radios
              title="Metric selection"
              value={metric}
              options={METRICS}
              handleChange={handleRadios(setMetric)}
            />
          </Grid>
          <Grid item>
            <Checkboxes
              title="Events selection"
              options={EVENTS.map(({ key, label }) => ({
                key: `${key}`,
                checked: eventTypes.indexOf(`${key}`) !== -1,
                label,
              }))}
              handleChange={handleCheckboxes(setEventTypes)}
            />
          </Grid>
          <Grid item>
            <Checkboxes
              title="Disconnections"
              options={DISCONNECTIONS.map(({ key, label }) => ({
                key,
                checked: disconnectionsSide.indexOf(key) !== -1,
                label,
              }))}
              handleChange={handleCheckboxes(setDisconnectionsSide)}
            />
          </Grid>
          <Grid item>
            <Checkboxes
              title="RSSI"
              options={RSSI.map(({ key, label }) => ({
                key,
                checked: rssiSide.indexOf(key) !== -1,
                label,
              }))}
              handleChange={handleCheckboxes(setRSSISide)}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <TimelineRecord
          strides={filterStrides()}
          rssi={filterRssi(rssi, rssiSide)}
          disconnections={filterDisconnections(connectionTimestamps, disconnectionsSide)}
          events={filterEvents(events, eventTypes)}
          startTime={record.startTime}
          stopTime={record.stopTime}
        />
      </CardContent>
    </Card>
  );
}

RecordMetricsGraphWrapper.propTypes = {
  recordId: PropTypes.string.isRequired,
  record: PropTypes.shape({
    startTime: PropTypes.number,
    stopTime: PropTypes.number,
    counterLeftStride: PropTypes.number,
    counterRightStride: PropTypes.number,
  }).isRequired,
};

export default RecordMetricsGraphWrapper;
