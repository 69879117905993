import React from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
  Grid,
} from '@material-ui/core';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';

import UserAvatar from './components/user-avatar';
import SignOut from './components/sign-out';

import { getCurrentUser } from '../firebase/auth';
import { fetchCurrentUserProfile } from '../firebase/store';

class User extends React.Component {
  constructor() {
    super();

    this.state = {
      inProgress: true,
      displayName: undefined,
    };
  }

  componentDidMount() {
    fetchCurrentUserProfile().then((userProfile) => {
      if (userProfile && userProfile.displayName) {
        this.setState({ displayName: userProfile.displayName });
      }

      this.setState({ inProgress: false });
    });
  }

  render() {
    if (this.state.inProgress === true) {
      return null;
    }

    const userFirebase = getCurrentUser();
    // we need at least a name to display an avatar
    const displayName = this.state.displayName || userFirebase.displayName;
    const showAvatar = displayName && displayName.length > 0;

    return (
      <div>
        <PageHeader>
          <PageTitle>User</PageTitle>
        </PageHeader>
        <Card>
          { showAvatar && (
            <CardHeader
              avatar={<UserAvatar displayName={displayName} photoURL={userFirebase.photoURL} />}
              title={displayName}
            />
          )}
          <CardContent>
            <Typography>
              {`Email: ${userFirebase.email}`}
            </Typography>
            <Typography>
              {`ID: ${userFirebase.uid}`}
            </Typography>

            <Grid container direction="row" justify="flex-end" alignItems="center">
              <Grid item>
                <SignOut />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default User;
