import React from 'react';
import PropTypes from 'prop-types';

function flattenObject(ob) {
  const flatten = {};

  Object.keys(ob).forEach((k) => {
    if ((typeof ob[k]) === 'object') {
      const flatObj = flattenObject(ob[k]);
      Object.keys(flatObj).forEach((x) => {
        flatten[`${k}.${x}`] = flatObj[x];
      });
    } else {
      flatten[k] = ob[k];
    }
  });
  return flatten;
}

function RecordInsole(props) {
  const flat = flattenObject(props.insole);

  return (
    <pre>
      <code className="prettyprint">
        {JSON.stringify(flat, Object.keys(flat).sort(), 2)}
      </code>
    </pre>
  );
}

RecordInsole.propTypes = {
  insole: PropTypes.shape({}).isRequired,
};

export default RecordInsole;
