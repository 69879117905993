import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import QueryFieldToggle from '../../components/display/query-field-toggle';

import { updateQueryInsoleMac } from '../../actions/index';

function mapStateToProps(state) {
  return ({
    macAddress: state.query.macAddress,
  });
}

function QueryInsoleMac({ macAddress, dispatch }) {
  return (
    <QueryFieldToggle
      value={macAddress}
      switchValue="macAddress"
      switchLabel="All mac addresses"
      onSwitchChange={ev => dispatch(updateQueryInsoleMac(ev.target.checked ? 'all' : '00:00:00:00:00:00'))}
      textLabel="Mac address"
      onTextChange={ev => dispatch(updateQueryInsoleMac(ev.target.value))}
    />
  );
}

QueryInsoleMac.propTypes = {
  macAddress: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(QueryInsoleMac);
