function isFlagged(stride) {
  // algo flags
  if ('flag' in stride && stride.flag !== 0) {
    return true;
  }

  // system flags
  return stride.overflow === true
    || stride.dtOverflow === true
    || stride.corruption === true;
}

export {
  // eslint-disable-next-line import/prefer-default-export
  isFlagged,
};
