import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Grid,
  Switch,
  FormControlLabel,
} from '@material-ui/core/';
import { KeyboardDatePicker } from '@material-ui/pickers';

import { updateQueryBeforeDate } from '../../actions/index';

function mapStateToProps(state) {
  return ({
    beforeDate: state.query.beforeDate,
  });
}

// set date at the end of the day to refresh issue when waiting for a record
function getEndOfDay(date) {
  date.setHours(23, 59, 59);
  return date.getTime();
}

function QueryBeforeDate({ beforeDate, dispatch }) {
  function onChange(date) {
    dispatch(updateQueryBeforeDate(`${getEndOfDay(date)}`));
  }

  function onDefaultValue(ev) {
    dispatch(updateQueryBeforeDate(ev.target.checked ? `${getEndOfDay(new Date())}` : 'all'));
  }

  const useDate = beforeDate !== 'all';

  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start" spacing={4}>
      <Grid item>
        <FormControlLabel
          control={(
            <Switch
              checked={useDate}
              onChange={onDefaultValue}
              value={beforeDate}
            />
          )}
          label="Use date"
        />
      </Grid>
      <Grid item>
        <KeyboardDatePicker
          value={!useDate ? undefined : new Date(parseInt(beforeDate, 10))}
          disabled={!useDate}
          onChange={onChange}
          format="dd/MM/yyyy"
          label="Before the"
        />
      </Grid>
    </Grid>
  );
}

QueryBeforeDate.propTypes = {
  beforeDate: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(QueryBeforeDate);
