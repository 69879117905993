import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

import { getPalette } from './utils/colors';

export default createMuiTheme({
  typography: {
    allVariants: {
      letterSpacing: 0,
    },
  },
  palette: getPalette(),
  error: red.A400,
});
