import { formatDistance, format, addMilliseconds } from 'date-fns';

function timeDifferenceStrict(dateA, dateB, pattern = 'HH:mm:ss') {
  const differenceMs = Math.abs(dateB - dateA);
  // date without timezone
  // not pretty, we should change it when date-fns support duration
  const date = new Date(0 + (new Date(0).getTimezoneOffset() * 1000 * 60));
  return format(addMilliseconds(date, differenceMs), pattern);
}

function timeDifference(dateA, dateB) {
  return formatDistance(new Date(dateA), new Date(dateB));
}

export {
  timeDifferenceStrict,
  timeDifference,
};
