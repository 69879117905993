import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import RecordItem from '../../components/record/index';

function mapStateToProps(state) {
  return ({
    records: state.records,
  });
}

function RecordList(props) {
  return props.records.map(record => <RecordItem record={record} key={record.key} />);
}

RecordList.propTypes = {
  records: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
  })).isRequired,
};

export default connect(mapStateToProps)(RecordList);
