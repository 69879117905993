import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography,
  LinearProgress,
  TextField,
} from '@material-ui/core';

import OrganisationsName from '../components/organisation/names';

import { fetchUser, fetchOrga, fetchAuthorizations } from '../firebase/store';

function displayObjectKey(value, text) {
  if (value) {
    return (
      <Typography>
        {`${text}: ${value}`}
      </Typography>
    );
  }

  return undefined;
}

function Users() {
  const [selectedUserId, setSelectedUserId] = useState('');
  const [user, setUser] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [orga, setOrga] = useState({});
  const [authorizations, setAuthorizations] = useState({});

  useEffect(() => {
    async function wrapper() {
      setInProgress(true);
      const userFetched = await fetchUser(selectedUserId);
      setUser(userFetched);
      if (userFetched.orgaId) {
        setOrga(await fetchOrga(userFetched.orgaId));
      }

      setAuthorizations(await fetchAuthorizations(selectedUserId));

      setInProgress(false);
    }

    if (selectedUserId.length > 0) {
      wrapper();
    }
  }, [selectedUserId]);

  return (
    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
      <Grid item>
        <Card>
          <div style={{ minHeight: '5px' }}>
            { inProgress && (
              <LinearProgress />
            )}
          </div>
          <CardHeader title="Search UserId" />
          <CardContent>
            <TextField
              label="UserId"
              value={selectedUserId}
              onChange={ev => setSelectedUserId(ev.target.value)}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item>
        { (inProgress === false && Object.keys(user).length > 0) && (
          <Card>
            <CardContent>
              { displayObjectKey(user.displayName, 'Display name') }
              { displayObjectKey(user.rehabUser, 'Rehab user') }
              { orga && displayObjectKey(orga.name, 'Member of the orga') }
              { authorizations.length > 0 && (
                <OrganisationsName ids={authorizations.map(i => i.key)} title="Patient organisations" />
              )}
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
}

export default Users;
