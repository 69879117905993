const SIDES = [{
  key: 'left',
  label: 'Left',
}, {
  key: 'right',
  label: 'Right',
}];

export {
  // eslint-disable-next-line import/prefer-default-export
  SIDES,
};
