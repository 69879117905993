import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core';

function Radios(props) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{props.title}</FormLabel>
      <RadioGroup
        aria-label={props.title}
        name={props.title}
        value={props.value}
        onChange={ev => props.handleChange(ev)}
      >
        {props.options.map(option => (
          <FormControlLabel
            key={option.key}
            value={option.key}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

Radios.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    label: PropTypes.string,
  })).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Radios;
