import React from 'react';
import Grid from '@material-ui/core/Grid';

import PageHeader from '../components/display/page-header';
import PageTitle from '../components/display/page-title';

import Create from './components/create';
import List from './components/list';

function Links() {
  return (
    <div>
      <PageHeader>
        <PageTitle>Links</PageTitle>
      </PageHeader>
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={3}>
        <Grid item>
          <Create />
        </Grid>
        <Grid item>
          <List />
        </Grid>
      </Grid>
    </div>
  );
}

export default Links;
