import firebase from './index';

import {
  recordRef,
  insoleRef,
  strideRef,
  connectionTimestampRef,
  linkRef,
  orgaRef,
  roleRef,
  userRef,
  rssiRef,
  authorizationsRef,
  eventRef,
} from './ref';

import { getCurrentUser } from './auth';

const DB = firebase.firestore();

// save the key of the document into the document data
function saveDocumentKey(doc, key = 'key', parentId = false) {
  if (!doc.exists) {
    return undefined;
  }

  const res = { ...doc.data(), [key]: doc.id };
  if (parentId) {
    // eslint-disable-next-line dot-notation
    res['__parent'] = doc.ref.parent.parent.id;
  }
  return res;
}

// convert a firestore snapshot to an array
// the key of the document is available in the document data
function snapshotToArray(querySnapshot, key, parentId) {
  const result = [];
  querySnapshot.forEach(doc => result.push(saveDocumentKey(doc, key, parentId)));

  return result;
}

function generateRecordQuery(limit, authorId, application, connection, record, beforeDate, orgaId) {
  let query = recordRef(DB);

  if (authorId !== 'all') {
    query = query.where('authorId', '==', authorId);
  }

  if (application !== 'all') {
    query = query.where('application.name', '==', application);
  }

  if (connection !== 'all') {
    query = query.where('type', '==', connection);
  }

  if (record !== 'all') {
    query = query.where('recordType', '==', record);
  }

  if (orgaId !== 'all') {
    query = query.where('authorizationsId', 'array-contains', orgaId);
  }

  query = query.orderBy('startTime', 'desc');

  if (beforeDate !== 'all') {
    query = query.startAfter(parseInt(beforeDate, 10));
  }

  if (limit !== 'all') {
    query = query.limit(parseInt(limit, 10));
  }

  return query;
}

function generateInsoleGroupQuery(
  limit,
  macAddress,
  number,
  side,
  size,
  versionNordic,
  versionAtmel,
) {
  let query = DB.collectionGroup('insoles');

  if (macAddress !== 'all') {
    query = query.where('macAddress', '==', macAddress.toUpperCase().replace(/-/g, ':'));
  }

  if (number !== 'all') {
    query = query.where('number', '==', parseInt(number, 10));
  }

  if (side !== 'all') {
    query = query.where('side', '==', side);
  }

  if (size !== 'all') {
    query = query.where('size', '==', parseInt(size, 10));
  }

  if (versionNordic !== 'all') {
    query = query.where('versions.firmware', '==', versionNordic);
  }

  if (versionAtmel !== 'all') {
    query = query.where('versions.atmel', '==', versionAtmel);
  }

  if (limit !== 'all') {
    query = query.limit(parseInt(limit, 10));
  }

  return query;
}

//
// FETCH
//
function isGroupFeetMe(userId) {
  return roleRef(DB, userId).get()
    .then(doc => doc.data().isFeetMe === true)
    .catch(() => false);
}

function isGroupAdmin(userId) {
  return roleRef(DB, userId).get()
    .then(doc => doc.data().isAdmin === true)
    .catch(() => false);
}

function fetchRole(userId) {
  return roleRef(DB, userId).get().then(doc => (doc.exists ? doc.data() : {}));
}

function fetchInsoles(recordId) {
  return insoleRef(recordRef(DB, recordId)).get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchInsolesGroup(limit, mac, number, side, size, versionNordic, versionAtmel) {
  return generateInsoleGroupQuery(limit, mac, number, side, size, versionNordic, versionAtmel)
    .get()
    .then(querySnapshot => snapshotToArray(querySnapshot, 'key', true));
}

function fetchRecords(limit, authorId, application, connection, record, beforeDate, orgaId) {
  return generateRecordQuery(limit, authorId, application, connection, record, beforeDate, orgaId)
    .get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchNextBatchRecords(
  limit,
  authorId,
  application,
  connection,
  record,
  lastStartTime,
  orgaId,
) {
  return generateRecordQuery(limit, authorId, application, connection, record, undefined, orgaId)
    .startAfter(lastStartTime)
    .get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchRecord(recordId) {
  return recordRef(DB, recordId).get()
    .then(doc => saveDocumentKey(doc));
}

function fetchStrides(recordId) {
  return strideRef(recordRef(DB, recordId)).orderBy('clientTimestamp', 'asc').get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchBatchStrides(recordId, batchSize, previousClientTimestamp) {
  let request = strideRef(recordRef(DB, recordId))
    .orderBy('clientTimestamp', 'asc');

  if (previousClientTimestamp !== undefined) {
    request = request.startAfter(previousClientTimestamp);
  }

  return request.limit(batchSize)
    .get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchRSSI(recordId) {
  return rssiRef(recordRef(DB, recordId)).orderBy('clientTimestamp', 'asc').get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchConnectionTimestamp(recordId) {
  return connectionTimestampRef(recordRef(DB, recordId))
    .orderBy('startConnectionTimestamp', 'asc').get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchLinks() {
  return linkRef(DB).orderBy('createdAt', 'desc').limit(50).get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchOrgas() {
  return orgaRef(DB).get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchOrga(orgaId) {
  return orgaRef(DB, orgaId).get()
    .then(doc => saveDocumentKey(doc));
}

function fetchUser(userId) {
  return userRef(DB, userId).get()
    .then(doc => saveDocumentKey(doc));
}

function fetchCurrentUserProfile() {
  return fetchUser(getCurrentUser().uid);
}

function fetchEvents(recordId) {
  return eventRef(recordRef(DB, recordId)).orderBy('clientTimestamp', 'asc').get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

function fetchAuthorizations(userId) {
  return authorizationsRef(userRef(DB, userId)).get()
    .then(querySnapshot => snapshotToArray(querySnapshot));
}

export {
  isGroupFeetMe,
  isGroupAdmin,

  fetchRole,
  fetchInsoles,
  fetchInsolesGroup,
  fetchRecords,
  fetchNextBatchRecords,
  fetchRecord,
  fetchStrides,
  fetchBatchStrides,
  fetchRSSI,
  fetchConnectionTimestamp,
  fetchLinks,
  fetchOrgas,
  fetchOrga,
  fetchUser,
  fetchCurrentUserProfile,
  fetchEvents,
  fetchAuthorizations,
};
