import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function getOS(name) {
  if (name.startsWith('android')) {
    return 'Android';
  }

  if (name.startsWith('ios')) {
    return 'iOS';
  }

  return '';
}

function RecordApplicationOS({ application }) {
  if (!('name' in application)) {
    return null;
  }

  return (
    <Typography>
      { getOS(application.name) }
    </Typography>
  );
}

RecordApplicationOS.propTypes = {
  application: PropTypes.shape({
    name: PropTypes.string,
  }),
};

RecordApplicationOS.defaultProps = {
  application: {},
};

export default RecordApplicationOS;
