import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { fetchInsoles } from '../../firebase/store';

import RecordInsole from './record-insole';

class RecordInsoles extends React.Component {
  constructor() {
    super();

    this.state = {
      insoles: [],
    };

    this.unmounted = false;
  }

  componentDidMount() {
    fetchInsoles(this.props.recordID).then((insoles) => {
      // FIXME: should try to cancel the fetch
      if (!this.unmounted) {
        this.setState({ insoles });
      }
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (this.state.insoles.length !== nextState.insoles.length);
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  render() {
    return (
      <div>
        <Typography variant="subtitle1">
          Insoles
        </Typography>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          {
            this.state.insoles.map(insole => (
              <Grid item key={insole.macAddress}>
                <RecordInsole insole={insole} />
              </Grid>
            ))
          }
        </Grid>
      </div>
    );
  }
}

RecordInsoles.propTypes = {
  recordID: PropTypes.string.isRequired,
};

export default RecordInsoles;
