import firebase from 'firebase/app';

require('firebase/auth');
require('firebase/firestore');
require('firebase/storage');
require('firebase/functions');

// eslint-disable-next-line no-undef
const config = __FIREBASE_CONFIG__;

firebase.initializeApp(config);

export default firebase;
