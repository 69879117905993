import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import DateDisplay from '../display/date';
import { timeDifference } from '../../utils/time';

function RecordTitle(props) {
  return (
    <Grid container direction="row" justify="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <Typography>
          <DateDisplay value={props.startTime} />
        </Typography>
      </Grid>
      { props.stopTime !== undefined && (
        <Grid item>
          <Typography>
            { `(${timeDifference(props.startTime, props.stopTime)})` }
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}

RecordTitle.propTypes = {
  startTime: PropTypes.number.isRequired,
  stopTime: PropTypes.number,
};

RecordTitle.defaultProps = {
  stopTime: undefined,
};

export default RecordTitle;
