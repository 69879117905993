import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import QueryFieldToggle from '../../components/display/query-field-toggle';

import { updateQueryInsoleSize } from '../../actions/index';

function mapStateToProps(state) {
  return ({
    size: state.query.size,
  });
}

function QueryInsoleSize({ size, dispatch }) {
  return (
    <QueryFieldToggle
      value={size}
      switchValue="insoleSize"
      switchLabel="All insole sizes"
      onSwitchChange={ev => dispatch(updateQueryInsoleSize(ev.target.checked ? 'all' : '42'))}
      textLabel="Size"
      onTextChange={ev => dispatch(updateQueryInsoleSize(ev.target.value))}
    />
  );
}

QueryInsoleSize.propTypes = {
  size: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(QueryInsoleSize);
