import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  TextField,
  Card,
  CardHeader,
  CardContent,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core/';

import { createNewOrganisation } from '../../firebase/functions';

const styles = () => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

class Create extends React.Component {
  constructor() {
    super();

    this.state = {
      name: '',
      inProgress: false,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { name } = this.state;
    this.setState({ name: '', inProgress: true });
    createNewOrganisation({ name })
      .then(() => this.setState({ inProgress: false }))
    // eslint-disable-next-line no-console
      .catch(err => console.error(err));
  }

  render() {
    return (
      <Card>
        <div style={{ minHeight: '5px' }}>
          { this.state.inProgress && (
            <LinearProgress />
          )}
        </div>
        <CardHeader title="Create organisation" />
        <CardContent>
          <Grid container direction="row" justify="space-around" alignItems="center">
            <Grid item>
              <TextField
                id="organisation-name"
                label="Organisation name"
                value={this.state.name}
                onChange={ev => this.setState({ name: ev.target.value })}
                margin="normal"
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={this.handleClick}
                disabled={this.state.name.length === 0 || this.state.inProgress}
              >
                Create
                { this.state.inProgress && (
                  <CircularProgress size={24} className={this.props.classes.buttonProgress} />
                )}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

Create.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Create);
