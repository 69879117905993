import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Radios from '../../components/selection-controls/radios';

import { updateQueryInsoleSide } from '../../actions/index';

function mapStateToProps(state) {
  return ({
    side: state.query.side,
  });
}

function QueryInsoleSide({ side, dispatch }) {
  return (
    <Radios
      title="Side"
      value={side}
      options={[{
        key: 'all',
        label: 'All',
      }, {
        key: 'left',
        label: 'Left',
      }, {
        key: 'right',
        label: 'Right',
      }]}
      handleChange={ev => dispatch(updateQueryInsoleSide(ev.target.value))}
    />
  );
}

QueryInsoleSide.propTypes = {
  side: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(QueryInsoleSide);
