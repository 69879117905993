import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import Radios from '../../components/selection-controls/radios';

import TimelineAllRecords from './timeline-all-records';

import { getStartOfDate } from '../../utils/date';

const TYPES = [{
  key: 'day',
  label: 'Day',
}, {
  key: 'week',
  label: 'Week',
}, {
  key: 'month',
  label: 'Month',
}, {
  key: 'year',
  label: 'Year',
}];

// todo
// remove records-graph-wrapper
// check start of date
// prepare trend graph
// check for empty value on day/week/... (in records graph)

function mergeRecords(records, type) {
  const res = [];

  // records are sort in decreasing order
  records.forEach((record) => {
    const startDateRecord = getStartOfDate(record.x, type);
    if (res.length === 0 || (startDateRecord.getTime() !== res[res.length - 1].x.getTime())) {
      res.push({
        x: startDateRecord,
        y: 1,
      });
    } else {
      res[res.length - 1].y += 1;
    }
  });

  return res;
}

function AllRecordsGraphWrapper(props) {
  const [displayType, setDisplayType] = useState('week');

  return (
    <Card>
      <CardHeader title={`Number of records: ${props.records.length}`} />
      <CardContent>
        <Grid container direction="row" justify="space-around" alignItems="flex-start">
          <Grid item>
            <Radios
              title="Display per"
              value={displayType}
              options={TYPES}
              handleChange={ev => setDisplayType(ev.target.value)}
            />
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        <TimelineAllRecords data={mergeRecords(props.records, displayType)} />
      </CardContent>
    </Card>
  );
}

AllRecordsGraphWrapper.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AllRecordsGraphWrapper;
