import {
  startOfDay,
  startOfWeek,
  startOfMonth,
  startOfYear,

  isSameDay,
  isSameISOWeek,
  isSameMonth,
  isSameYear,
} from 'date-fns';

function getStartOfDate(date, type) {
  switch (type) {
    case 'week':
      return startOfWeek(new Date(date));
    case 'month':
      return startOfMonth(new Date(date));
    case 'year':
      return startOfYear(new Date(date));
    default:
      // default to day
      return startOfDay(new Date(date));
  }
}

function isSameDate(d1, d2, type) {
  switch (type) {
    case 'week':
      return isSameISOWeek(new Date(d1), new Date(d2));
    case 'month':
      return isSameMonth(new Date(d1), new Date(d2));
    case 'year':
      return isSameYear(new Date(d1), new Date(d2));
    default:
      // default to day
      return isSameDay(new Date(d1), new Date(d2));
  }
}

export {
  getStartOfDate,
  isSameDate,
};
