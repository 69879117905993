import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
} from '@material-ui/core';

import ProgressWait from '../../components/display/progress-wait';
import RecordRawFlags from './record-raw-flags';
import RecordRawGraphWrapper from './record-raw-graph-wrapper';

import { capaCSV, imuCSV } from '../../utils/csv-download';
import { fetchRSSI, fetchConnectionTimestamp } from '../../firebase/store';
import { cleanDisconnections } from '../../utils/record';

function RecordRawInfo({ recordId, record }) {
  const [capa, setCapa] = useState([]);
  const [imu, setImu] = useState([]);
  const [connectionTimestamp, setConnectionTimestamp] = useState([]);
  const [rssi, setRSSI] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);

  const { startTime, stopTime } = record;

  function loadData() {
    setDataLoading(true);
    const capaPromise = capaCSV(recordId).then(csv => setCapa(csv
      .split('\n')
      .slice(2)
      .map(line => line.split(',').map(v => (Number.isNaN(Number(v)) ? v.trim('\r') : Number(v))))))
      .catch(() => Promise.resolve());
    const imuPromise = imuCSV(recordId).then(csv => setImu(csv
      .split('\n')
      .slice(2)
      .map(line => line.split(',').map(v => (Number.isNaN(Number(v)) ? v.trim('\r') : Number(v))))))
      .catch(() => Promise.resolve());
    const connectionTimestampPromise = fetchConnectionTimestamp(recordId)
      .then(i => setConnectionTimestamp(cleanDisconnections(i, startTime, stopTime)))
      .catch(() => Promise.resolve());
    const rssiPromise = fetchRSSI(recordId).then(i => setRSSI(i))
      .catch(() => Promise.resolve());

    Promise.all([
      capaPromise,
      imuPromise,
      connectionTimestampPromise,
      rssiPromise,
    ]).then(() => setDataLoaded(true));
  }

  if (dataLoaded === true) {
    return (
      <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={1}>
        <Grid item>
          <RecordRawFlags capa={capa} imu={imu} />
        </Grid>
        <Grid item>
          <RecordRawGraphWrapper
            capa={capa}
            imu={imu}
            rssi={rssi}
            connectionTimestamp={connectionTimestamp}
            startTime={startTime}
            stopTime={stopTime}
          />
        </Grid>
      </Grid>
    );
  }

  if (dataLoading === false) {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          <Button variant="contained" color="primary" onClick={loadData}>
            Load data
          </Button>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container direction="row" justify="center" alignItems="center">
      <Grid item>
        <ProgressWait />
      </Grid>
    </Grid>
  );
}

RecordRawInfo.propTypes = {
  recordId: PropTypes.string.isRequired,
  record: PropTypes.shape({
    startTime: PropTypes.number,
    stopTime: PropTypes.number,
  }).isRequired,
};

export default RecordRawInfo;
