import React from 'react';
import PropTypes from 'prop-types';
import { extent } from 'd3-array';
import { scaleLinear } from 'd3-scale';

import {
  Circles,
  Axis,
  accessorPropType,
  useGraphDimensionsContext,
} from '@feetme/d3act';

import { getColorLeftSide, getColorRightSide } from '../../utils/colors';

function TimelineRecordStrides({ data, xAccessor, noAxis }) {
  const dimensions = useGraphDimensionsContext();

  const yAccessor = d => d.y;

  const yScale = scaleLinear()
    .domain(extent(data, yAccessor))
    .range([dimensions.boundedHeight, 0]);

  const yAccessorScaled = d => yScale(yAccessor(d));

  return (
    <React.Fragment>
      <g>
        <Circles
          data={data.filter(i => i.side === 'left')}
          keyAccessor={d => `${d.x}${d.y}`}
          xAccessor={xAccessor}
          yAccessor={yAccessorScaled}
          fillAccessor={getColorLeftSide()}
          radiusAccessor={3.5}
          style={{ transition: 'all 0.3s ease-out' }}
        />
        <Circles
          data={data.filter(i => i.side === 'right')}
          keyAccessor={d => `${d.x}${d.y}`}
          xAccessor={xAccessor}
          yAccessor={yAccessorScaled}
          fillAccessor={getColorRightSide()}
          radiusAccessor={3.5}
          style={{ transition: 'all 0.3s ease-out' }}
        />
      </g>
      { !noAxis && (
        <Axis
          dimension="y"
          scale={yScale}
          keyAccessor={d => d}
        />
      )}
    </React.Fragment>
  );
}

TimelineRecordStrides.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    side: PropTypes.string,
  })).isRequired,
  xAccessor: accessorPropType.isRequired,
  noAxis: PropTypes.bool,
};

TimelineRecordStrides.defaultProps = {
  noAxis: false,
};

export default TimelineRecordStrides;
