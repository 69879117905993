import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

function TooltipInfoEventIntercom({ event }) {
  return (
    <Grid container direction="column" justify="flex-start" alignItems="flex-start">
      <Grid item>
        <Typography>Intercom event</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{ `side: ${event.side}` }</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{ `dt insole: ${event.dtInsole}` }</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{ `timestamp paired insole: ${event.timestampPairedInsole}` }</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{ `dt paired insole: ${event.dtPairedInsole}` }</Typography>
      </Grid>
      <Grid item>
        <Typography variant="caption">{ `mac paired insole: ${event.macPairedInsole}` }</Typography>
      </Grid>
    </Grid>
  );
}

TooltipInfoEventIntercom.propTypes = {
  event: PropTypes.shape({
    side: PropTypes.oneOf(['left', 'right']),
    dtInsole: PropTypes.number,
    timestampPairedInsole: PropTypes.number,
    dtPairedInsole: PropTypes.number,
    macPairedInsole: PropTypes.string,
  }).isRequired,
};

export default TooltipInfoEventIntercom;
