import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core/';

import SearchCard from '../../components/display/search-card';
import QueryInsoleMac from './query-mac-address';
import QueryInsoleNumber from './query-number';
import QueryInsoleSize from './query-size';
import QueryInsoleSide from './query-side';
import QueryInsoleVersionNordic from './query-version-nordic';
import QueryInsoleVersionAtmel from './query-version-atmel';
import QueryLimitFetch from '../../records/components/query-limit-fetch';

function InsoleSearch(props) {
  const {
    inProgress,
    onRefresh,
  } = props;

  return (
    <SearchCard inProgress={inProgress} onRefresh={onRefresh}>
      <Grid container direction="row" justify="space-around" alignItems="flex-start">
        <Grid item>
          <QueryInsoleMac />
        </Grid>
        <Grid item>
          <QueryInsoleNumber />
        </Grid>
        <Grid item>
          <QueryInsoleSize />
        </Grid>
        <Grid item>
          <QueryInsoleSide />
        </Grid>
        <Grid item>
          <QueryInsoleVersionNordic />
        </Grid>
        <Grid item>
          <QueryInsoleVersionAtmel />
        </Grid>
        <Grid item>
          <QueryLimitFetch />
        </Grid>
      </Grid>
    </SearchCard>
  );
}

InsoleSearch.propTypes = {
  onRefresh: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
};

export default InsoleSearch;
