import React from 'react';
import PropTypes from 'prop-types';
import { extent } from 'd3-array';
import { curveMonotoneX } from 'd3-shape';
import { scaleLinear } from 'd3-scale';
import { interpolateSinebow } from 'd3-scale-chromatic';

import {
  Path,
  Axis,
  accessorPropType,
  useGraphDimensionsContext,
} from '@feetme/d3act';

import { SIDES } from '../../utils/sides';

function TimelineRecordCapa({ data, xAccessor, noAxis }) {
  const dimensions = useGraphDimensionsContext();

  let nbSensors = 0;
  if (data.length > 0) {
    nbSensors = data[0].y.length - 1;
  }

  const yScale = scaleLinear()
    .domain(extent(data.map(i => i.y).flat()))
    .range([dimensions.boundedHeight, 0]);

  const yAccessorScaled = d => yScale(d.y);

  return (
    <React.Fragment>
      <g>
        {SIDES.map(i => i.key).map(side => [...Array(nbSensors).keys()].map(i => (
          <Path
            key={i}
            data={
              data
                .filter(frame => frame.side === side)
                .map(frame => ({ x: frame.x, y: frame.y[i] }))
            }
            xAccessor={xAccessor}
            yAccessor={yAccessorScaled}
            interpolation={curveMonotoneX}
            stroke={nbSensors > 0 ? interpolateSinebow(i / nbSensors) : 'black'}
            strokeWidth={1}
          />
        )))}
      </g>
      { !noAxis && (
        <Axis
          dimension="y"
          scale={yScale}
          keyAccessor={d => d}
        />
      )}
    </React.Fragment>
  );
}

TimelineRecordCapa.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.arrayOf(PropTypes.number),
    side: PropTypes.string,
  })).isRequired,
  xAccessor: accessorPropType.isRequired,
  noAxis: PropTypes.bool,
};

TimelineRecordCapa.defaultProps = {
  noAxis: false,
};

export default TimelineRecordCapa;
