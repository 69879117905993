import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import { fetchOrga } from '../../firebase/store';

function OrganisationsName({ ids, title }) {
  const [organisations, setOrganisations] = useState([]);

  useEffect(() => {
    async function wrapper() {
      setOrganisations(await Promise.all(ids.map(async id => ({
        id,
        orga: await fetchOrga(id),
      }))));
    }

    wrapper();
  }, [ids]);

  return (
    <div>
      <Typography>
        {title}
      </Typography>
      <List dense>
        { organisations.map((orga) => {
          if (orga.orga === undefined) {
            return (
              <ListItem key={orga.id}>
                <ListItemText primary={orga.id} />
              </ListItem>
            );
          }

          return (
            <ListItem key={orga.orga.key}>
              <ListItemText primary={orga.orga.name} />
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}

OrganisationsName.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

export default OrganisationsName;
