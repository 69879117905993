import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';

function Checkboxes(props) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">{props.title}</FormLabel>
      <FormGroup>
        {props.options.map(option => (
          <FormControlLabel
            key={option.key}
            control={(
              <Checkbox
                checked={option.checked}
                onChange={ev => props.handleChange(option.key, ev)}
                value={option.key}
              />
            )}
            label={option.label}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

Checkboxes.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    checked: PropTypes.bool,
    label: PropTypes.string,
  })).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default Checkboxes;
