import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import OrganisationsName from '../organisation/names';

function displayRecordKey(counter, text) {
  if (counter) {
    return (
      <Typography>
        {`${text}: ${counter}`}
      </Typography>
    );
  }

  return undefined;
}

function getCounters(side, record) {
  return (
    <div>
      <Typography variant="body1">
        {`${side} counters`}
      </Typography>
      { displayRecordKey(record[`counter${side}IMU`], 'IMU counter') }
      { displayRecordKey(record[`counter${side}Capa`], 'Capa counter') }
      { displayRecordKey(record[`counter${side}Stride`], 'Stride counter') }
      { displayRecordKey(record[`counter${side}MissStride`], 'Miss stride counter') }
      { displayRecordKey(record[`counter${side}Pressure`], 'Pressure counter') }
    </div>
  );
}

function RecordInfo(props) {
  const keys = Object.keys(props.record);
  const hasLeftCounters = keys.find(key => key.includes('counterLeft')) !== undefined;
  const hasRightCounters = keys.find(key => key.includes('counterRight')) !== undefined;
  return (
    <div>
      <Typography variant="subtitle1">
        Record
      </Typography>
      <Typography>
        {`Key: ${props.record.key}`}
      </Typography>
      { displayRecordKey(props.record.authorId, 'Author id') }
      { (props.record.authorizationsId && props.record.authorizationsId.length > 0) && (
        <OrganisationsName ids={props.record.authorizationsId} title="Organisations" />
      )}
      { displayRecordKey(props.record.type, 'Type') }
      { displayRecordKey(props.record.recordType, 'Record type') }
      {
        (hasLeftCounters || hasRightCounters) && (
          <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
            {
              hasLeftCounters && (
                <Grid item>
                  { getCounters('Left', props.record) }
                </Grid>
              )
            }
            {
              hasRightCounters && (
                <Grid item>
                  { getCounters('Right', props.record) }
                </Grid>
              )
            }
          </Grid>
        )
      }
      { /* Support for old counter format */ }
      { displayRecordKey(props.record.counterStep, 'Stride counter') }
      { displayRecordKey(props.record.counterIMU, 'IMU counter') }
      { displayRecordKey(props.record.counterCapa, 'Capa counter') }
    </div>
  );
}

RecordInfo.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string,
    type: PropTypes.string,
    recordType: PropTypes.string,
    counterStep: PropTypes.number,
    counterCapa: PropTypes.number,
    counterIMU: PropTypes.number,
    authorId: PropTypes.string,
    authorizationsId: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

export default RecordInfo;
