import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  Toolbar,
  Typography,
} from '@material-ui/core';

import ViewListIcon from '@material-ui/icons/ViewList';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import LinkIcon from '@material-ui/icons/Link';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DomainIcon from '@material-ui/icons/Domain';
import BluetoothIcon from '@material-ui/icons/Bluetooth';
import PeopleIcon from '@material-ui/icons/People';

import DrawerLink from './drawer-link';

import { fetchRole } from '../../firebase/store';
import { userID } from '../../firebase/auth';

const DRAWER_WIDTH = 230;

const styles = theme => ({
  toolbar: {
    ...theme.mixins.toolbar,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
    outline: 'none',
  },
  title: {
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  appBar: {
    marginLeft: DRAWER_WIDTH,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  },
});

function pageToTitle(page) {
  const title = page.split('/')[1];

  return title.charAt(0).toUpperCase() + title.slice(1);
}

class ToolBarDrawer extends React.Component {
  constructor() {
    super();

    this.state = {
      mobileOpen: false,
      isGroupAdmin: false,
      isGroupFeetMe: false,
    };

    this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    this.handleDrawerClose = this.handleDrawerClose.bind(this);
  }

  componentDidMount() {
    fetchRole(userID()).then(({ isAdmin, isFeetMe }) => this.setState({
      isGroupAdmin: isAdmin === true,
      isGroupFeetMe: isFeetMe === true,
    }));
  }

  handleDrawerToggle() {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  }

  handleDrawerClose() {
    this.setState({ mobileOpen: false });
  }

  render() {
    const { classes } = this.props;

    const drawer = (
      <div>
        <div className={classes.toolbar}>
          <Link className={classes.link} to="/" onClick={this.handleDrawerClose}>
            <Typography className={classes.title} variant="h6" color="inherit">
              FeetMe Palios
            </Typography>
          </Link>
        </div>
        <Divider />
        <List>
          <DrawerLink to="/records" text="Records" onClick={this.handleDrawerClose}>
            <ViewListIcon />
          </DrawerLink>
          { this.state.isGroupFeetMe && (
            <DrawerLink to="/insoles" text="Insoles" onClick={this.handleDrawerClose}>
              <DirectionsWalkIcon />
            </DrawerLink>
          )}
          { this.state.isGroupAdmin && (
            <DrawerLink to="/links" text="Links" onClick={this.handleDrawerClose}>
              <LinkIcon />
            </DrawerLink>
          )}
          { this.state.isGroupFeetMe && (
            <DrawerLink to="/organisations" text="Organisations" onClick={this.handleDrawerClose}>
              <DomainIcon />
            </DrawerLink>
          )}
          { this.state.isGroupFeetMe && (
            <DrawerLink to="/users" text="Users" onClick={this.handleDrawerClose}>
              <PeopleIcon />
            </DrawerLink>
          )}
          { this.state.isGroupFeetMe && (
            <DrawerLink href to="https://webble.feetme.dev" text="Bluetooth" onClick={this.handleDrawerClose}>
              <BluetoothIcon />
            </DrawerLink>
          )}
          <DrawerLink to="/user" text="User" onClick={this.handleDrawerClose}>
            <AccountCircleIcon />
          </DrawerLink>
        </List>
      </div>
    );

    return (
      <React.Fragment>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              { pageToTitle(this.props.location.pathname) }
            </Typography>
          </Toolbar>
        </AppBar>
        <nav className={classes.drawer}>
          <Hidden smUp implementation="css">
            <Drawer
              variant="temporary"
              anchor="left"
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              classes={{ paper: classes.drawerPaper }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{ paper: classes.drawerPaper }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
      </React.Fragment>
    );
  }
}

ToolBarDrawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  location: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(ToolBarDrawer));
