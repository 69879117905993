import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import RecordMetricsGraphWrapper from './record-metrics-graph-wrapper';
import RecordMetricsHealth from './record-metrics-health';

function RecordMetricsInfo({ record, recordId }) {
  return (
    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
      <Grid item>
        <RecordMetricsHealth recordId={recordId} record={record} />
      </Grid>
      <Grid item>
        <RecordMetricsGraphWrapper recordId={recordId} record={record} />
      </Grid>
    </Grid>
  );
}

RecordMetricsInfo.propTypes = {
  recordId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  record: PropTypes.object.isRequired,
};

export default RecordMetricsInfo;
