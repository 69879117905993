import queryString from 'query-string';

function getQueryString(
  limitFetch,
  authorId,
  organisationId,
  applicationType,
  connectionType,
  recordType,
  beforeDate,
  exoType,
) {
  return queryString.stringify({
    limitFetch,
    authorId,
    organisationId,
    applicationType,
    connectionType,
    recordType,
    beforeDate,
    exoType,
  });
}

function parseQueryString(searchString) {
  return queryString.parse(searchString);
}

export {
  getQueryString,
  parseQueryString,
};
