import firebase from './index';

const addLink = firebase.functions().httpsCallable('link-addLink');

const createNewOrganisation = firebase.functions().httpsCallable('organisation-createNewOrganisation');

export {
  addLink,

  createNewOrganisation,
};
