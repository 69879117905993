function getMobilityDashboard() {
  // eslint-disable-next-line no-undef
  if (__FIREBASE_CONFIG__.projectId.indexOf('dev') > 0) {
    return 'https://dial.feetme.dev/dashboard';
  }

  return 'https://mobility.feetme.fr/dashboard';
}

function getPalios() {
  // eslint-disable-next-line no-undef
  if (__FIREBASE_CONFIG__.projectId.indexOf('dev') > 0) {
    return 'https://palios.feetme.dev';
  }

  return 'https://palios.feetme.fr';
}

export {
  getMobilityDashboard,
  getPalios,
};
