import React from 'react';
import {
  IconButton,
} from '@material-ui/core/';
import RefreshIcon from '@material-ui/icons/Refresh';

import LinkItem from '../../components/link/index';

import { fetchLinks } from '../../firebase/store';

class List extends React.Component {
  constructor() {
    super();

    this.state = {
      links: [],
    };

    this.fetch = this.fetch.bind(this);
  }

  componentDidMount() {
    this.fetch();
  }

  fetch() {
    fetchLinks()
      .then(links => this.setState({
        links,
      }));
  }

  render() {
    return (
      <div>
        <IconButton color="secondary" aria-label="refresh" onClick={() => this.fetch()}>
          <RefreshIcon />
        </IconButton>
        { this.state.links.map(link => <LinkItem link={link} key={link.key} />) }
      </div>
    );
  }
}

export default List;
