const TYPES = [{
  key: 'standard',
  label: 'Standard',
  source: ['dios', 'dynamic'],
}, {
  key: '2min',
  label: '2 min',
  source: ['dios', 'dynamic'],
}, {
  key: '6min',
  label: '6 min',
  source: ['dios', 'dynamic'],
}, {
  key: '10meters',
  label: '10 meters',
  source: ['dios', 'dynamic'],
}, {
  key: '400meters',
  label: '400 meters',
  source: ['dios', 'dynamic'],
}, {
  key: '25foot',
  label: '25 foot',
  source: ['dios', 'dynamic'],
}, {
  key: 'synchro',
  label: 'Synchro',
  source: ['dios'],
}, {
  key: 'posturofeedback',
  label: 'Posturo feedback',
  source: ['rehab'],
}, {
  key: 'velocity',
  label: 'Balance & speed',
  source: ['rehab'],
}, {
  key: 'stabilisation',
  label: 'Stabilization',
  source: ['rehab'],
}, {
  key: 'clockedwalk',
  label: 'Rhythmic stimulation',
  source: ['rehab'],
}, {
  key: 'heelstrike',
  label: 'Heel strike - endurance',
  source: ['rehab'],
}, {
  key: 'walkingtest',
  label: 'Free walking',
  source: ['rehab'],
}, {
  key: 'heelstrikegait',
  label: 'Heel strike - gait quality',
  source: ['rehab'],
}, {
  key: 'stridelength',
  label: 'Big steps',
  source: ['rehab'],
}, {
  key: 'plantarflexion',
  label: 'Plantar flexion',
  source: ['rehab'],
}, {
  key: 'lateralwalking',
  label: 'Lateral walking',
  source: ['rehab'],
}, {
  key: 'sittostand',
  label: 'Sit-to-stand',
  source: ['rehab'],
}];

function getTypes(connectionType) {
  if (connectionType === 'all') {
    return TYPES;
  }

  return TYPES.filter(type => type.source.indexOf(connectionType) > -1);
}

function getLabelFromKey(connectionType, key) {
  const typeFound = getTypes(connectionType).find(type => type.key === key);

  if (typeFound !== undefined) {
    return typeFound.label;
  }

  return undefined;
}

export {
  getTypes,
  getLabelFromKey,
};
