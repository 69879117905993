import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';
import { differenceInWeeks, getDay } from 'date-fns';

import TimelineRecordsTrend from './timeline-records-trend';

import { isSameDate } from '../../utils/date';

function recordsPerWeekdays(records) {
  const currentWeek = Array(7).fill(0);
  const previousWeeks = Array(7).fill(0);

  const today = new Date();

  if (records.length === 0) {
    return ({ currentWeek, trend: 0 });
  }

  records.forEach((record) => {
    if (isSameDate(record.x, today, 'week')) {
      currentWeek[getDay(new Date(record.x) - 1)] += 1;
    } else {
      previousWeeks[getDay(new Date(record.x) - 1)] += 1;
    }
  });

  const nbWeeks = Math.max(1, Math.abs(differenceInWeeks(
    new Date(records[0].x),
    new Date(records[records.length - 1].x),
  )));

  return ({
    currentWeek,
    trend: previousWeeks.map(i => i / nbWeeks),
  });
}

function RecordsTrendWrapper({ records }) {
  const { currentWeek, trend } = recordsPerWeekdays(records);
  return (
    <Card>
      <CardHeader title="Record trend" />
      <CardContent>
        <TimelineRecordsTrend currentWeek={currentWeek} trend={trend} />
      </CardContent>
    </Card>
  );
}

RecordsTrendWrapper.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default RecordsTrendWrapper;
