import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core';

import Checkboxes from '../../components/selection-controls/checkboxes';
import TimelineRaw from './timeline-raw';

import { filterRssi, filterDisconnections } from '../../utils/record';
import { SIDES } from '../../utils/sides';
import { RSSI } from '../../utils/rssi';
import { DISCONNECTIONS } from '../../utils/disconnections';

const RAW_TYPES = [{
  key: 'capa',
  label: 'Capa',
}, {
  key: 'gyro',
  label: 'Gyro',
}, {
  key: 'accelero',
  label: 'Accelero',
}];

function RecordRawGraphWrapper(props) {
  const {
    capa,
    imu,
    connectionTimestamp,
    rssi,
    startTime,
    stopTime,
  } = props;

  const [sides, setSides] = useState(SIDES.map(side => side.key));
  const [rawTypes, setRawTypes] = useState(RAW_TYPES.map(i => i.key));
  const [rssiSides, setRssiSides] = useState(RSSI.map(i => i.key));
  const [disconnectionsSides, setDisconnectionsSides] = useState(DISCONNECTIONS.map(i => i.key));

  function callbackHandleCheckBox(key, ev, set) {
    if (ev.target.checked) {
      set(previous => ([...previous, key]));
    } else {
      set(previous => (previous.filter(i => i !== key)));
    }
  }

  function filterCapa() {
    if (rawTypes.indexOf('capa') === -1) {
      return [];
    }

    return capa.filter(i => sides.indexOf(i[i.length - 1]) > -1)
      .map(i => ({
        x: i[0],
        y: i.slice(2, i.length - 2),
        side: i[i.length - 1],
      }));
  }

  function filterGyro() {
    if (rawTypes.indexOf('gyro') === -1) {
      return [];
    }

    return imu.filter(i => sides.indexOf(i[i.length - 1]) > -1)
      .map(i => ({
        x: i[0],
        y: [i[2], i[3], i[4]],
        side: i[i.length - 1],
      }));
  }

  function filterAccelero() {
    if (rawTypes.indexOf('accelero') === -1) {
      return [];
    }

    return imu.filter(i => sides.indexOf(i[i.length - 1]) > -1)
      .map(i => ({
        x: i[0],
        y: [i[5], i[6], i[7]],
        side: i[i.length - 1],
      }));
  }

  function filterInsoleTimestamps() {
    // XXX we could also merge with the capa
    return imu.filter(i => sides.indexOf(i[i.length - 1]) > -1)
      .map(i => ({
        x: i[0],
        insoleTimestamp: i[1],
        side: i[i.length - 1],
      }));
  }

  return (
    <Card>
      <CardHeader title="Raw display" />
      <CardContent>
        <Grid container direction="row" justify="space-around" alignItems="flex-start">
          <Grid item>
            <Checkboxes
              title="Sides selection"
              options={SIDES.map(({ key, label }) => ({
                key,
                checked: sides.indexOf(key) !== -1,
                label,
              }))}
              handleChange={(key, ev) => callbackHandleCheckBox(key, ev, setSides)}
            />
          </Grid>
          <Grid item>
            <Checkboxes
              title="Raw data selection"
              options={RAW_TYPES.map(({ key, label }) => ({
                key,
                checked: rawTypes.indexOf(key) !== -1,
                label,
              }))}
              handleChange={(key, ev) => callbackHandleCheckBox(key, ev, setRawTypes)}
            />
          </Grid>
          <Grid item>
            <Checkboxes
              title="Disconnections"
              options={DISCONNECTIONS.map(({ key, label }) => ({
                key,
                checked: disconnectionsSides.indexOf(key) !== -1,
                label,
              }))}
              handleChange={(key, ev) => callbackHandleCheckBox(key, ev, setDisconnectionsSides)}
            />
          </Grid>
          <Grid item>
            <Checkboxes
              title="RSSI"
              options={RSSI.map(({ key, label }) => ({
                key,
                checked: rssiSides.indexOf(key) !== -1,
                label,
              }))}
              handleChange={(key, ev) => callbackHandleCheckBox(key, ev, setRssiSides)}
            />
          </Grid>
        </Grid>
        <TimelineRaw
          capa={filterCapa()}
          gyro={filterGyro()}
          accelero={filterAccelero()}
          insoleTimestamps={filterInsoleTimestamps()}
          rssi={filterRssi(rssi, rssiSides)}
          disconnections={filterDisconnections(connectionTimestamp, disconnectionsSides)}
          startTime={startTime}
          stopTime={stopTime}
        />
      </CardContent>
    </Card>
  );
}

RecordRawGraphWrapper.propTypes = {
  capa: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]))).isRequired,
  imu: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]))).isRequired,
  connectionTimestamp: PropTypes.arrayOf(PropTypes.object).isRequired,
  rssi: PropTypes.arrayOf(PropTypes.object).isRequired,
  startTime: PropTypes.number.isRequired,
  stopTime: PropTypes.number.isRequired,
};

export default RecordRawGraphWrapper;
