import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import { fetchEvents } from '../../firebase/store';
import {
  diosCSV,
  connectionTimestampCSV,
  imuCSV,
  capaCSV,
  capaMultipleSensorsTestCSV,
  rssiStorageCSV,
  rssiFirestoreCSV,
  rawFrequenciesCSV,
  batteryCSV,
  bqCSV,
  capa16CSV,
  eventsTraceCSV,
  eventsIntercomCSV,
  eventsGravityVectorCSV,
} from '../../utils/csv-download';

import RecordDownload from './record-download';

function getImuDownloadButton(recordID, tag) {
  return <RecordDownload type="imu" recordID={recordID} tagRecord={tag} downloadFn={imuCSV} />;
}

function getCapaDownloadButton(recordID, tag) {
  return <RecordDownload type="capa" recordID={recordID} tagRecord={tag} downloadFn={capaCSV} />;
}

function getDiosDownloadButton(recordID, tag) {
  return <RecordDownload type="metrics" recordID={recordID} tagRecord={tag} downloadFn={diosCSV} />;
}

function getCapaMultipleSensorsTest(recordID, tag) {
  return <RecordDownload type="capa test" recordID={recordID} tagRecord={tag} downloadFn={capaMultipleSensorsTestCSV} />;
}

const downloadButtons = {
  dios: (recordID, tag) => [getDiosDownloadButton(recordID, tag)],
  imu: (recordID, tag) => [getImuDownloadButton(recordID, tag)],
  capa: (recordID, tag) => [getCapaDownloadButton(recordID, tag)],
  'capa-imu': (recordID, tag) => [
    getImuDownloadButton(recordID, tag),
    getCapaDownloadButton(recordID, tag),
  ],
  'raw-frequencies': (recordID, tag) => [
    <RecordDownload
      type="raw-frequencies"
      recordID={recordID}
      tagRecord={tag}
      downloadFn={rawFrequenciesCSV}
    />,
  ],
  charge: (recordID, tag) => [
    <RecordDownload type="battery" recordID={recordID} tagRecord={tag} downloadFn={batteryCSV} />,
    <RecordDownload type="bq" recordID={recordID} tagRecord={tag} downloadFn={bqCSV} />,
  ],
  'synchro-dios': (recordID, tag) => [getDiosDownloadButton(recordID, tag)],
  'capa-multiple-sensors-test': (recordID, tag) => [getCapaMultipleSensorsTest(recordID, tag)],
  'capa16-imu': (recordID, tag) => [
    <RecordDownload type="capa16" recordID={recordID} tagRecord={tag} downloadFn={capa16CSV} />,
    getImuDownloadButton(recordID, tag),
  ],
};

function RecordDownloadSwitch(props) {
  const downloadChilds = [];

  const [hasEvents, setHasEvents] = useState(false);

  if (props.type in downloadButtons) {
    downloadChilds.push(...downloadButtons[props.type](props.recordID, props.tagRecord));
  }

  if (props.type === 'rehab' && props.recordType === 'walkingtest') {
    downloadChilds.push(...downloadButtons.dios(props.recordID, props.tagRecord));
  }

  useEffect(() => {
    async function checkEvents() {
      const events = await fetchEvents(props.recordID);
      setHasEvents(events && events.length > 0);
    }

    checkEvents();
  }, []);

  return (
    <Grid container direction="row" justify="flex-start" alignItems="center">
      { downloadChilds.map(child => (
        <Grid item key={child.props.type}>
          { child }
        </Grid>
      ))}
      { props.type !== 'rehab' && (
        <Grid item>
          <RecordDownload
            type="connection-timestamp"
            recordID={props.recordID}
            tagRecord={props.tagRecord}
            downloadFn={connectionTimestampCSV}
          />
        </Grid>
      )}
      { props.useRSSI && (
        <Grid item>
          <RecordDownload
            type="rssi"
            recordID={props.recordID}
            tagRecord={props.tagRecord}
            downloadFn={rssiStorageCSV}
          />
        </Grid>
      )}
      { (!props.useRSSI && props.type !== 'rehab') && (
        <Grid item>
          <RecordDownload
            type="rssi"
            recordID={props.recordID}
            tagRecord={props.tagRecord}
            downloadFn={rssiFirestoreCSV}
          />
        </Grid>
      )}
      { hasEvents && (
        <React.Fragment>
          <Grid item>
            <RecordDownload
              type="trace-events"
              recordID={props.recordID}
              tagRecord={props.tagRecord}
              downloadFn={eventsTraceCSV}
            />
          </Grid>
          <Grid item>
            <RecordDownload
              type="intercom-events"
              recordID={props.recordID}
              tagRecord={props.tagRecord}
              downloadFn={eventsIntercomCSV}
            />
          </Grid>
          <Grid item>
            <RecordDownload
              type="gravity-vector-events"
              recordID={props.recordID}
              tagRecord={props.tagRecord}
              downloadFn={eventsGravityVectorCSV}
            />
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
}

RecordDownloadSwitch.propTypes = {
  type: PropTypes.string.isRequired,
  recordType: PropTypes.string,
  tagRecord: PropTypes.string,
  recordID: PropTypes.string.isRequired,
  useRSSI: PropTypes.bool,
};

RecordDownloadSwitch.defaultProps = {
  recordType: undefined,
  tagRecord: undefined,
  useRSSI: false,
};

export default RecordDownloadSwitch;
