import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

import theme from './material-ui-theme';
import Root from './root/index';
import reducers from './reducers/index';
import { isProdTarget, isProductionBuild } from './utils/env';

const store = createStore(reducers);

if (isProductionBuild()) {
  Sentry.init({
    // eslint-disable-next-line no-undef
    dsn: __SENTRY_DSN__,
    environment: (isProdTarget() ? 'production' : 'development'),
    // eslint-disable-next-line no-undef
    release: __COMMIT_HASH__,
  });
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      </MuiPickersUtilsProvider>
    </Provider>
  </ThemeProvider>,
  document.getElementById('feetme-sgr-app'),
);
