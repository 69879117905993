import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core/';

import SearchCard from '../../components/display/search-card';
import QueryBeforeDate from './query-before-date';
import QueryApplicationType from './query-application-type';
import QueryAuthorId from './query-author-id';
import QueryOrganisationId from './query-organisation-id';
import QueryLimitFetch from './query-limit-fetch';
import QueryConnectionType from './query-connection-type';
import QueryRecordType from './query-record-type';

import { useIsInFeetMeGroup } from '../../utils/hooks';

function mapStateToProps(state) {
  return ({
    connectionType: state.query.connectionType,
    applicationType: state.query.applicationType,
  });
}

function showConnectionType(application) {
  return application === 'all'
    || application === 'android-dashboard'
    || application === 'android-rehab'
    || application === 'ios-rehab';
}

function showRecordType(connectionType) {
  return connectionType === 'dios'
    || connectionType === 'rehab'
    || connectionType === 'dynamic';
}

function RecordSearch(props) {
  const {
    applicationType,
    connectionType,
    inProgress,
    onRefresh,
  } = props;

  const isInFeetMeGroup = useIsInFeetMeGroup();

  return (
    <SearchCard inProgress={inProgress} onRefresh={onRefresh}>
      <Grid container direction="row" spacing={3}>
        {isInFeetMeGroup && (
          <Grid item xs>
            <QueryAuthorId />
          </Grid>
        )}
        {isInFeetMeGroup && (
          <Grid item xs>
            <QueryOrganisationId />
          </Grid>
        )}
        <Grid item xs>
          <QueryBeforeDate />
        </Grid>
        <Grid item xs>
          <Grid container direction="column" justify="flex-start" alignItems="stretch">
            <Grid item>
              <QueryApplicationType />
            </Grid>
            {showConnectionType(applicationType) && (
              <Grid item>
                <QueryConnectionType />
              </Grid>
            )}
            <Grid item>
              {showRecordType(connectionType) && (
                <QueryRecordType />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs>
          <QueryLimitFetch />
        </Grid>
      </Grid>
    </SearchCard>
  );
}

RecordSearch.propTypes = {
  connectionType: PropTypes.string.isRequired,
  applicationType: PropTypes.string.isRequired,
  onRefresh: PropTypes.func.isRequired,
  inProgress: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(RecordSearch);
