import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  Button,
  Divider,
  Typography,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Inspector from 'react-inspector';

function OrganisationItem(props) {
  return (
    <Accordion defaultExpanded={props.directLink}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>
          { props.organisation.name }
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" justify="space-between" alignItems="flex-start" spacing={2}>
          <Grid item>
            <Typography variant="subtitle1">
              Organisation data
            </Typography>
          </Grid>
          <Grid>
            <Inspector
              data={props.organisation}
              name="organisation"
              expandLevel={2}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider />
      { props.directLink === false && (
        <AccordionActions>
          <Button
            component={Link}
            to={`/organisation/${props.organisation.key}`}
          >
            Stats
          </Button>
        </AccordionActions>
      )}
    </Accordion>
  );
}

OrganisationItem.propTypes = {
  organisation: PropTypes.shape({
    key: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  directLink: PropTypes.bool,
};

OrganisationItem.defaultProps = {
  directLink: false,
};

export default OrganisationItem;
