function roundPrecision(i) {
  return Math.round(i * 100) / 100;
}

function differencePercentage(a, b) {
  return roundPrecision((Math.abs(a - b) / ((a + b) / 2)) * 100);
}

export {
  roundPrecision,
  differencePercentage,
};
