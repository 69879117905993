import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { isProdTarget, getTargetEnvName } from '../../utils/env';

const styles = theme => ({
  root: {
    position: 'fixed',
    right: '8px',
    bottom: '8px',
    color: 'rgba(98, 98, 98, 0.6)',
    zIndex: theme.zIndex.appBar + 1,
  },
  dev: {
    color: theme.palette.error.main,
  },
});

function GitHash(props) {
  return (
    <Typography variant="caption" className={props.classes.root}>
      {
        isProdTarget()
          ? ''
          : (
            <span>
              <b className={props.classes.dev}>
                { getTargetEnvName() }
              </b>
              { ' ' }
            </span>
          )
      }
      {
        // eslint-disable-next-line no-undef
        __COMMIT_HASH__
      }
    </Typography>
  );
}

GitHash.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GitHash);
