import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import { getLabelFromKey } from '../../utils/record-type';

function recordTypeDisplay(type, recordType) {
  if (recordType === null || recordType.length === 0) {
    return '';
  }

  const label = getLabelFromKey(type, recordType);
  if (label === undefined) {
    return '';
  }

  return ` - ${label}`;
}

function RecordType(props) {
  return (
    <Typography>
      {`${props.type}${recordTypeDisplay(props.type, props.recordType)}`}
    </Typography>
  );
}

RecordType.propTypes = {
  type: PropTypes.string.isRequired,
  recordType: PropTypes.string,
};

RecordType.defaultProps = {
  recordType: null,
};

export default RecordType;
