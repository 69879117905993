import React from 'react';
import PropTypes from 'prop-types';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { extent } from 'd3-array';
import { scaleLinear } from 'd3-scale';

import {
  Graph,
  Axis,
  Path,
  useGraphDimensions,
} from '@feetme/d3act';

function formatWeekDay(d, i) {
  switch (i) {
    case 0:
      return 'Monday';
    case 1:
      return 'Tuesday';
    case 2:
      return 'Wednesday';
    case 3:
      return 'Thursday';
    case 4:
      return 'Friday';
    case 5:
      return 'Saturday';
    case 6:
      return 'Sunday';
    default:
      return 'Unknown weekday';
  }
}

const useStyles = makeStyles({
  root: {
    height: '500px',
    width: '100%',
  },
});

function TimelineRecordsTrend({ currentWeek, trend }) {
  const [ref, dimensions] = useGraphDimensions();
  const classes = useStyles();
  const theme = useTheme();

  const xScale = scaleLinear()
    .domain([0, 6])
    .range([0, dimensions.boundedWidth]);

  const yScale = scaleLinear()
    .domain(extent([].concat(currentWeek, trend)))
    .range([dimensions.boundedHeight, 0]);

  const xAccessorScaled = (d, i) => xScale(i);
  const yAccessorScaled = d => yScale(d);

  return (
    <div className={classes.root} ref={ref}>
      <Graph dimensions={dimensions}>
        <Axis
          dimension="x"
          scale={xScale}
          formatTick={formatWeekDay}
          keyAccessor={d => d}
        />
        <Axis
          dimension="y"
          scale={yScale}
          fullTick
          keyAccessor={d => d}
        />
        <Path
          data={trend}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          stroke={theme.palette.secondary.main}
          strokeWidth={2}
          strokeDasharray="4,4"
        />
        <Path
          data={currentWeek}
          xAccessor={xAccessorScaled}
          yAccessor={yAccessorScaled}
          stroke={theme.palette.secondary.main}
          strokeWidth={2}
        />
      </Graph>
    </div>
  );
}

TimelineRecordsTrend.propTypes = {
  currentWeek: PropTypes.arrayOf(PropTypes.number).isRequired,
  trend: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default TimelineRecordsTrend;
