import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateQueryRecordType } from '../../actions/index';
import { getTypes } from '../../utils/record-type';
import SimpleSelect from '../../components/selection-controls/simple-select';

function mapStateToProps(state) {
  return ({
    recordType: state.query.recordType,
    connectionType: state.query.connectionType,
  });
}

class QueryRecordType extends React.Component {
  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(ev) {
    this.props.dispatch(updateQueryRecordType(ev.target.value));
  }

  render() {
    return (
      <SimpleSelect
        id="record-select"
        title="Record type"
        value={this.props.recordType}
        options={[{
          key: 'all',
          label: 'All',
        }, ...getTypes(this.props.connectionType)]}
        onChange={this.handleChange}
      />
    );
  }
}

QueryRecordType.propTypes = {
  recordType: PropTypes.string.isRequired,
  connectionType: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(QueryRecordType);
