import React from 'react';
import PropTypes from 'prop-types';
import { extent } from 'd3-array';
import { scaleLinear } from 'd3-scale';

import {
  Path,
  accessorPropType,
  useGraphDimensionsContext,
} from '@feetme/d3act';

import { getColorLeftSide, getColorRightSide } from '../../utils/colors';

function TimelineInsoleTimestampSide(props) {
  const {
    data,
    xAccessor,
    yAccessor,
    color,
  } = props;

  const dimensions = useGraphDimensionsContext();

  const yScale = scaleLinear()
    .domain(extent(data, yAccessor))
    .range([dimensions.boundedHeight, 0]);

  const yAccessorScaled = d => yScale(yAccessor(d));

  return (
    <Path
      data={data}
      xAccessor={xAccessor}
      yAccessor={yAccessorScaled}
      strokeWidth={1}
      stroke={color}
    />
  );
}

TimelineInsoleTimestampSide.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  xAccessor: accessorPropType.isRequired,
  yAccessor: accessorPropType.isRequired,
  color: PropTypes.string.isRequired,
};

function TimelineInsoleTimestamp(props) {
  const {
    data,
    xAccessor,
  } = props;

  const yAccessor = d => d.insoleTimestamp;

  const dataLeft = data.filter(i => i.side === 'left');
  const dataRight = data.filter(i => i.side === 'right');

  return (
    <g>
      <TimelineInsoleTimestampSide
        data={dataLeft}
        xAccessor={xAccessor}
        yAccessor={yAccessor}
        color={getColorLeftSide()}
      />
      <TimelineInsoleTimestampSide
        data={dataRight}
        xAccessor={xAccessor}
        yAccessor={yAccessor}
        color={getColorRightSide()}
      />
    </g>
  );
}

TimelineInsoleTimestamp.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    x: PropTypes.number,
    side: PropTypes.oneOf(['left', 'right']),
    insoleTimestamp: PropTypes.number,
  })).isRequired,
  xAccessor: accessorPropType.isRequired,
};

export default TimelineInsoleTimestamp;
