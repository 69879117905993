import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function RecordTags(props) {
  if (props.tags === null) {
    return null;
  }

  return (
    <Typography>
      { props.tags.join(', ') }
    </Typography>
  );
}

RecordTags.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};

RecordTags.defaultProps = {
  tags: [''],
};

export default RecordTags;
