import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  Divider,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { getMobilityDashboard } from '../../utils/url';
import { fetchOrga } from '../../firebase/store';

function isLinkValid(link) {
  if (link.isValid === false) {
    return false;
  }

  if (link.counter === 0) {
    return false;
  }

  if ((Date.now() - link.createdAt.toDate()) > link.expiration) {
    return false;
  }

  return true;
}

class LinkItem extends React.Component {
  constructor() {
    super();

    this.state = {
      orgaName: undefined,
    };
  }

  componentDidMount() {
    fetchOrga(this.props.link.orgaId).then(orga => this.setState({ orgaName: orga.name }));
  }

  render() {
    const isValid = isLinkValid(this.props.link);
    const borderStyle = {
      borderLeft: `3px solid ${isValid ? 'transparent' : 'grey'}`,
    };

    return (
      <Accordion style={borderStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Grid container direction="row" justify="flex-start" alignItems="center">
            <Grid item xs={4}>
              <Typography>
                { this.props.link.type }
              </Typography>
            </Grid>
            { this.state.orgaName && (
              <Grid item xs={4}>
                <Typography>
                  { this.state.orgaName }
                </Typography>
              </Grid>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row" justify="space-between" alignItems="flex-start">
            <Grid item>
              <Typography variant="subtitle1">
                Link data
              </Typography>
              <pre>
                <code className="prettyprint">
                  { JSON.stringify(this.props.link, null, 2) }
                </code>
              </pre>
            </Grid>
          </Grid>
        </AccordionDetails>
        { isValid && (
          <div>
            <Divider />
            <AccordionActions>
              <Button
                onClick={() => {
                  // format and copy link to clipboard
                  const el = document.createElement('textarea');
                  el.value = `${getMobilityDashboard()}/link/${this.props.link.key}`;
                  document.body.appendChild(el);
                  el.select();
                  document.execCommand('copy');
                  document.body.removeChild(el);
                }}
              >
                Copy link
              </Button>
            </AccordionActions>
          </div>
        )}
      </Accordion>
    );
  }
}

LinkItem.propTypes = {
  link: PropTypes.shape({
    type: PropTypes.string,
    key: PropTypes.string,
    orgaId: PropTypes.string,
    isValid: PropTypes.bool,
  }).isRequired,
};

export default LinkItem;
