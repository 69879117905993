import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Grid,
  Button,
  Divider,
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core/';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Inspector from 'react-inspector';

import RecordTitle from './record-title';
import RecordType from './record-type';
import RecordTags from './record-tags';

import RecordInfo from './record-info';
import RecordDuration from './record-duration';
import RecordApplication from './record-application';
import RecordApplicationOS from './record-application-os';
import RecordDevice from './record-device';
import RecordDownloadSwitch from './record-download-switch';
import RecordInsoles from './record-insoles';
import RecordWalkingAids from './record-walking-aids';
import RecordRehab from './record-rehab';

import { getColorApplication } from '../../utils/colors';
import { getMobilityDashboard } from '../../utils/url';

const styles = {
  directLinkButton: {
    marginLeft: 'auto',
  },
};

function showDialLink(record) {
  return record.type === 'dios'
    || (record.type === 'rehab' && record.recordType === 'walkingtest')
    || record.type === 'dynamic'
    || record.type === 'static';
}

function RecordItem(props) {
  const borderStyle = {};

  if (props.record.application !== undefined) {
    borderStyle.borderLeft = `3px solid ${getColorApplication(props.record.application.name)}`;
  }

  return (
    <Accordion defaultExpanded={props.directLink} style={borderStyle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container direction="row" justify="flex-start" alignItems="center">
          <Grid item xl={3} xs={5}>
            <RecordTitle startTime={props.record.startTime} stopTime={props.record.stopTime} />
          </Grid>
          <Grid item xs={3}>
            <RecordType type={props.record.type} recordType={props.record.recordType} />
          </Grid>
          <Grid item xs={3}>
            <RecordTags tags={props.record.tags} />
          </Grid>
          <Grid item xs={1}>
            <RecordApplicationOS application={props.record.application} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" justify="flex-start" alignItems="stretch">
          <Grid item>
            <Grid container direction="row" justify="space-between" alignItems="flex-start">
              <Grid item>
                <RecordInfo record={props.record} />
                <RecordDuration
                  startTime={props.record.startTime}
                  stopTime={props.record.stopTime}
                />
                { props.record.type === 'rehab' && (
                  <RecordRehab record={props.record} />
                )}
              </Grid>
              <Grid item>
                <RecordInsoles recordID={props.record.key} />
              </Grid>
              { props.record.walkingAids && (
                <Grid item>
                  <RecordWalkingAids walkingAids={props.record.walkingAids} />
                </Grid>
              )}
              <Grid item>
                <RecordDevice device={props.record.device} />
              </Grid>
              <Grid item>
                <RecordApplication application={props.record.application} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Inspector data={props.record} name="record" />
          </Grid>
        </Grid>
      </AccordionDetails>
      <Divider />
      <AccordionActions>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <RecordDownloadSwitch
              type={props.record.type}
              recordType={props.record.recordType}
              recordID={props.record.key}
              useRSSI={props.record.useRSSI}
              tagRecord={props.record.tags ? props.record.tags[0] : undefined}
            />
          </Grid>
          <Grid item>
            <Grid container direction="row" justify="flex-end" alignItems="center" spacing={2}>
              { (showDialLink(props.record)) && (
                <Grid item>
                  <Button
                    style={styles.directLinkButton}
                    href={`${getMobilityDashboard()}/record/${props.record.key}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Dial link
                  </Button>
                </Grid>
              )}
              {
                // display the button if we're not in directLink
                !props.directLink && (
                  <Grid item>
                    <Button
                      style={styles.directLinkButton}
                      component={Link}
                      to={`/record/${props.record.key}`}
                    >
                      Direct link
                    </Button>
                  </Grid>
                )
              }
            </Grid>
          </Grid>
        </Grid>
      </AccordionActions>
    </Accordion>
  );
}

RecordItem.propTypes = {
  record: PropTypes.shape({
    key: PropTypes.string.isRequired,
    startTime: PropTypes.number.isRequired,
    stopTime: PropTypes.number,
    application: PropTypes.shape.isRequired,
    device: PropTypes.shape.isRequired,
    type: PropTypes.string.isRequired,
    useRSSI: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.string),
    environment: PropTypes.arrayOf(PropTypes.string),
    recordType: PropTypes.string,
    walkingAids: PropTypes.arrayOf(PropTypes.shape),
  }).isRequired,
  directLink: PropTypes.bool,
};

RecordItem.defaultProps = {
  directLink: false,
};

export default RecordItem;
