import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';

import { fetchNextBatchRecords } from '../../firebase/store';
import { addRecords } from '../../actions/index';

function mapStateToProps(state) {
  return ({
    records: state.records,
    limitFetch: state.query.limitFetch,
    applicationType: state.query.applicationType,
    authorId: state.query.authorId,
    organisationId: state.query.organisationId,
    connectionType: state.query.connectionType,
    recordType: state.query.recordType,
  });
}

class LoadMoreRecords extends React.Component {
  constructor() {
    super();

    this.state = {
      inProgress: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const {
      limitFetch,
      authorId,
      organisationId,
      applicationType,
      records,
      connectionType,
      recordType,
    } = this.props;

    if (records.length === 0) {
      return;
    }

    this.setState({ inProgress: true });
    const { startTime } = records[records.length - 1];
    fetchNextBatchRecords(
      limitFetch,
      authorId,
      applicationType,
      connectionType,
      recordType,
      startTime,
      organisationId,
    )
      .then((newRecords) => {
        this.props.dispatch(addRecords(newRecords));
        this.setState({ inProgress: false });
      });
  }

  render() {
    return (
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item>
          {
            this.state.inProgress
              ? <CircularProgress />
              : (
                <Button onClick={this.onClick}>
                  Load more records
                </Button>
              )
          }
        </Grid>
      </Grid>
    );
  }
}

LoadMoreRecords.propTypes = {
  records: PropTypes.arrayOf(PropTypes.object).isRequired,
  limitFetch: PropTypes.string.isRequired,
  authorId: PropTypes.string.isRequired,
  organisationId: PropTypes.string.isRequired,
  applicationType: PropTypes.string.isRequired,
  connectionType: PropTypes.string.isRequired,
  recordType: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(LoadMoreRecords);
