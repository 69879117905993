import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
} from '@material-ui/core/colors';

const avatarColors = [
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  amber,
  orange,
  deepOrange,
  brown,
  grey,
  blueGrey,
];

function getColorAvatar(input) {
  let counter = 0;
  input.split('').map((char) => {
    counter += char.charCodeAt();
    return undefined;
  });
  return avatarColors[counter % avatarColors.length][500];
}

function getColorApplication(application) {
  if (application === 'android-lokara') {
    return blue['700'];
  }

  if (application === 'android-rehab' || application === 'ios-rehab') {
    return green['500'];
  }

  return deepOrange.A700;
}

function getPalette() {
  // https://material.io/tools/color/#!/?view.left=0&view.right=0&primary.color=E64A19&secondary.color=37474F
  return ({
    primary: {
      main: deepOrange.A700,
      contrastText: '#ffffff',
    },
    secondary: {
      main: blueGrey[800],
      contrastText: '#ffffff',
    },
  });
}

// Can we move those colors into the palette of material-ui
function getColorRightSide() {
  return blue[500];
}

function getColorLeftSide() {
  return red[500];
}

function getColorXYZ() {
  return ([red[500], green[500], cyan[500]]);
}

export {
  getColorAvatar,
  getColorApplication,
  getPalette,

  getColorRightSide,
  getColorLeftSide,

  getColorXYZ,
};
