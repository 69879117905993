import { INIT_RECORDS, ADD_RECORDS } from '../actions/types';

function records(state = [], action) {
  switch (action.type) {
    case INIT_RECORDS:
      return action.records;
    case ADD_RECORDS:
      return state.concat(action.records);
    default:
      return state;
  }
}

export default records;
