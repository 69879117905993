import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateQueryConnectionType, updateQueryRecordType } from '../../actions/index';
import { getTypes } from '../../utils/connection-type';
import SimpleSelect from '../../components/selection-controls/simple-select';

function mapStateToProps(state) {
  return ({
    connectionType: state.query.connectionType,
    applicationType: state.query.applicationType,
  });
}

class QueryConnectionType extends React.Component {
  constructor() {
    super();

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate() {
    if (this.props.connectionType !== 'dios'
      || this.props.connectionType !== 'rehab'
      || this.props.connectionType !== 'dynamic') {
      this.props.dispatch(updateQueryRecordType('all'));
    }
  }

  handleChange(ev) {
    this.props.dispatch(updateQueryConnectionType(ev.target.value));
  }

  render() {
    return (
      <SimpleSelect
        id="connection-select"
        title="Connection type"
        value={this.props.connectionType}
        options={[{
          key: 'all',
          label: 'All',
        }, ...getTypes(this.props.applicationType)]}
        onChange={this.handleChange}
      />
    );
  }
}

QueryConnectionType.propTypes = {
  connectionType: PropTypes.string.isRequired,
  applicationType: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(QueryConnectionType);
