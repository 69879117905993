import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

function RecordApplication(props) {
  return (
    <div>
      <Typography variant="subtitle1">
        Application
      </Typography>
      <pre>
        <code className="prettyprint">
          { JSON.stringify(props.application, null, 2) }
        </code>
      </pre>
    </div>
  );
}

RecordApplication.propTypes = {
  application: PropTypes.shape({}),
};

RecordApplication.defaultProps = {
  application: {},
};

export default RecordApplication;
