const INIT_RECORDS = 'INIT_RECORDS';
const ADD_RECORDS = 'ADD_RECORDS';

const UPDATE_QUERY_APPLICATION_TYPE = 'UPDATE_QUERY_APPLICATION_TYPE';
const UPDATE_QUERY_LIMIT_FETCH = 'UPDATE_QUERY_LIMIT_FETCH';
const UPDATE_QUERY_AUTHOR_ID = 'UPDATE_QUERY_AUTHOR_ID';
const UPDATE_QUERY_ORGANISATION_ID = 'UPDATE_QUERY_ORGANISATION_ID';
const UPDATE_QUERY_CONNECTION_TYPE = 'UPDATE_QUERY_CONNECTION_TYPE';
const UPDATE_QUERY_RECORD_TYPE = 'UPDATE_QUERY_RECORD_TYPE';
const UPDATE_QUERY_BEFORE_DATE = 'UPDATE_QUERY_BEFORE_DATE';
const UPDATE_QUERY_INSOLE_MAC = 'UPDATE_QUERY_INSOLE_MAC';
const UPDATE_QUERY_INSOLE_NUMBER = 'UPDATE_QUERY_INSOLE_NUMBER';
const UPDATE_QUERY_INSOLE_SIZE = 'UPDATE_QUERY_INSOLE_SIZE';
const UPDATE_QUERY_INSOLE_SIDE = 'UPDATE_QUERY_INSOLE_SIDE';
const UPDATE_QUERY_INSOLE_VERSION_NORDIC = 'UPDATE_QUERY_INSOLE_VERSION_NORDIC';
const UPDATE_QUERY_INSOLE_VERSION_ATMEL = 'UPDATE_QUERY_INSOLE_VERSION_ATMEL';
const UPDATE_QUERYS = 'UPDATE_QUERYS';

const UPDATE_WS_CONNECTION_STATE = 'UPDATE_WS_CONNECTION_STATE';

const FLUSH = 'FLUSH';

export {
  INIT_RECORDS,
  ADD_RECORDS,

  UPDATE_QUERY_APPLICATION_TYPE,
  UPDATE_QUERY_LIMIT_FETCH,
  UPDATE_QUERY_AUTHOR_ID,
  UPDATE_QUERY_ORGANISATION_ID,
  UPDATE_QUERY_CONNECTION_TYPE,
  UPDATE_QUERY_RECORD_TYPE,
  UPDATE_QUERY_BEFORE_DATE,
  UPDATE_QUERY_INSOLE_MAC,
  UPDATE_QUERY_INSOLE_NUMBER,
  UPDATE_QUERY_INSOLE_SIZE,
  UPDATE_QUERY_INSOLE_SIDE,
  UPDATE_QUERY_INSOLE_VERSION_NORDIC,
  UPDATE_QUERY_INSOLE_VERSION_ATMEL,
  UPDATE_QUERYS,

  UPDATE_WS_CONNECTION_STATE,

  FLUSH,
};
