import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Card,
  CardHeader,
  CardContent,
  LinearProgress,
  CircularProgress,
} from '@material-ui/core/';

import Radios from '../../components/selection-controls/radios';
import SimpleMenu from '../../components/selection-controls/simple-menu';

import { fetchOrgas } from '../../firebase/store';
import { addLink } from '../../firebase/functions';

const styles = () => ({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

const SEVEN_DAYS = `${7 * 24 * 60 * 60 * 1000}`;
const FIFTEEN_DAYS = `${14 * 24 * 60 * 60 * 1000}`;

class Create extends React.Component {
  constructor() {
    super();

    this.state = {
      type: 'orga',
      orgas: [],
      orgaId: '',
      inProgress: false,
      expiration: SEVEN_DAYS,
      counter: '',
    };

    fetchOrgas()
      .then(orgas => orgas.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      }))
      .then(orgas => this.setState({
        orgas: orgas.map(i => ({ key: i.key, label: i.name })),
        orgaId: orgas[0].key,
      }));

    this.onSelectOrga = this.onSelectOrga.bind(this);
    this.handleRadioChange = this.handleRadioChange.bind(this);
    this.createLink = this.createLink.bind(this);
  }

  handleRadioChange(type, ev) {
    this.setState({ [type]: ev.target.value });
  }

  onSelectOrga(key) {
    this.setState({ orgaId: key });
  }

  createLink() {
    let data = {
      type: this.state.type,
      orgaId: this.state.orgaId,
      expiration: Number(this.state.expiration),
    };

    if (this.state.counter.length !== 0) {
      data = { ...data, counter: Number(this.state.counter) };
    }

    this.setState({ inProgress: true });
    addLink(data)
      .then(() => this.setState({ inProgress: false }))
      .catch(() => this.setState({ inProgress: false }));
  }

  render() {
    return (
      <Card>
        <div style={{ minHeight: '5px' }}>
          { this.state.inProgress && (
            <LinearProgress />
          )}
        </div>
        <CardHeader title="Create link" />
        <CardContent>
          <Grid container direction="row" justify="space-evenly" alignItems="flex-start">
            <Grid item>
              <Radios
                title="Link type"
                value={this.state.type}
                options={[{
                  key: 'orga',
                  label: 'Join an organisation',
                }, {
                  key: 'orgaAdmin',
                  label: 'Join an organisation as an admin',
                }, {
                  key: 'authorization',
                  label: 'Authorize an organisation',
                }]}
                handleChange={ev => this.handleRadioChange('type', ev)}
              />
            </Grid>
            <Grid item>
              { this.state.orgas.length > 0 && (
                <SimpleMenu
                  id="menu-orga"
                  value={this.state.orgaId}
                  options={this.state.orgas}
                  onSelect={this.onSelectOrga}
                />
              )}
            </Grid>
            <Grid item>
              <Radios
                title="Expiration"
                value={this.state.expiration}
                options={[{
                  key: `${10 * 60 * 1000}`,
                  label: '10 min',
                }, {
                  key: `${3 * 60 * 60 * 1000}`,
                  label: '3 hours',
                }, {
                  key: `${24 * 60 * 60 * 1000}`,
                  label: '1 day',
                }, {
                  key: SEVEN_DAYS,
                  label: '7 days',
                }, {
                  key: FIFTEEN_DAYS,
                  label: '15 days',
                }]}
                handleChange={ev => this.handleRadioChange('expiration', ev)}
              />
            </Grid>
            <Grid item>
              <Radios
                title="Max clicks on link"
                value={this.state.counter}
                options={[{
                  key: '',
                  label: 'infinite',
                }, {
                  key: '1',
                  label: '1',
                }, {
                  key: '5',
                  label: '5',
                }, {
                  key: '10',
                  label: '10',
                }]}
                handleChange={ev => this.handleRadioChange('counter', ev)}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={this.createLink}
                disabled={this.state.inProgress
                  || (this.state.orgaId.length === 0)}
              >
                Generated a link
                { this.state.inProgress && (
                  <CircularProgress size={24} className={this.props.classes.buttonProgress} />
                )}
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

Create.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Create);
